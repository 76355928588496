<template>
  <div>
    <div class="mb-4">
      <label class="block text-sm font-medium text-gray-700">Projected average daily rate (ADR)</label>
      <div class="mt-1 relative rounded-md shadow-sm">
        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span class="text-gray-500 sm:text-sm">$</span>
        </div>
        <input 
          :value="dailyRate"
          @input="updateField('dailyRate', $event.target.value)"
          type="number"
          step="0.01"
          class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
        />
      </div>
      <p class="mt-2 text-xs">Over an average year, what will the property rent for per night?</p>
    </div>
    <div class="mb-4">
      <label class="block text-sm font-medium text-gray-700">Projected occupancy rate</label>
      <div class="mt-1 relative rounded-md shadow-sm">
        <input 
          :value="occupancyRate"
          @input="updateField('occupancyRate', $event.target.value)"
          type="number"
          step="0.1"
          class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
        />
        <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <span class="text-gray-500 sm:text-sm">%</span>
        </div>
      </div>
      <p class="mt-2 text-xs">What % of available days will be rented?</p>
    </div>
  </div>
</template>

<script setup>
import { computed, watch } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const emit = defineEmits(['section-completed']);

const dailyRate = computed(() => store.state.dailyRate);
const occupancyRate = computed(() => store.state.occupancyRate);

const updateField = (field, value) => {
  store.commit('updateField', { field, value: Number(value) });
};

watch([dailyRate, occupancyRate], ([newDailyRate, newOccupancyRate]) => {
  if (newDailyRate && newOccupancyRate) {
    emit('section-completed');
  }
});
</script>