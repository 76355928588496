import { createRouter, createWebHistory } from 'vue-router'
import { auth } from '@/services/auth'
import STRCalculator from '@/components/STRCalculator/STRCalculator.vue'
import SharedProperty from '@/components/SharedProperty.vue'
import ProfilePage from '@/components/ProfilePage.vue'

const routes = [
  {
    path: '/',
    component: STRCalculator
  },
  {
    path: '/property/:id',
    name: 'Property',
    component: STRCalculator,
    props: true,
    beforeEnter: async (to, from, next) => {
      const isAuthenticated = auth.isAuthenticated();
      const propertyId = to.params.id;
      if (isAuthenticated) {
        const property = await auth.getProperty(propertyId);
        const currentUser = auth.getCurrentUser();
        if (property && property.user === currentUser.id) {
          next(); // Allow access to STRCalculator for the owner
        } else {
          next({ name: 'SharedProperty', params: { id: propertyId } });
        }
      } else {
        next({ name: 'SharedProperty', params: { id: propertyId } });
      }
    }
  },
  {
    path: '/shared/:id',
    name: 'SharedProperty',
    component: SharedProperty,
    props: true
  },
  {
    path: '/profile',
    name: 'Profile',
    component: ProfilePage,
    meta: { requiresAuth: true }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!auth.isAuthenticated()) {
      next({ path: '/', query: { showAuthModal: 'true' } })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router