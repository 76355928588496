import { createStore } from 'vuex'
import { auth, pb } from '@/services/auth'

export default createStore({
  state: {
    user: null,
    guestCalculation: null,
    calculatorResults: [],
    currentPropertyId: null,
    currentTab: 'Calculator',
    isNewProperty: false,
    
    // Calculator fields
    address: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    showLoan: true,
    listingUrl: '',
    purchaseAmount: 0,
    downPaymentPer: 0,
    interestRate: 0,
    numYears: 0,
    closeCosts: 0,
    ownValue: 0,
    piPayment: 0,
    dailyRate: 0,
    occupancyRate: 0,
    propTaxes: 0,
    propIns: 0,
    propElec: 0,
    propGas: 0,
    propWater: 0,
    propTrash: 0,
    propInternet: 0,
    propSupplies: 0,
    propGifts: 0,
    propHOA: 0,
    propCleaning: 0,
    propAdv: 0,
    propMgt: 0,
    propRepairs: 0,
    propOther: 0,
    depYears: 27.5,
    impRatio: 0,
    taxBracket: 0,
    confirmationModal: {
      show: false,
      title: '',
      message: '',
      onConfirm: null,
    },
    messageModal: {
      show: false,
      title: '',
      message: '',
    },
  },
  
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    UPDATE_USER_PROFILE(state, { field, value }) {
      if (state.user) {
        state.user[field] = value;
      }
    },
    SET_GUEST_CALCULATION(state, calculation) {
      state.guestCalculation = calculation;
    },
    SET_IS_NEW_PROPERTY(state, value) {
      state.isNewProperty = value;
    },
    SET_CALCULATOR_RESULTS(state, results) {
      state.calculatorResults = results;
    },
    ADD_CALCULATOR_RESULT(state, result) {
      state.calculatorResults.unshift(result);
    },
    UPDATE_CALCULATOR_RESULT(state, updatedProperty) {
      const index = state.calculatorResults.findIndex(p => p.id === updatedProperty.id);
      if (index !== -1) {
        state.calculatorResults.splice(index, 1, updatedProperty);
      }
    },
    REMOVE_CALCULATOR_RESULT(state, resultId) {
      state.calculatorResults = state.calculatorResults.filter(r => r.id !== resultId);
    },
    updateField(state, { field, value }) {
      if (field === 'showLoan') {
        state[field] = Boolean(value);
      } else if (field === 'listingUrl') {
        state[field] = value; // Store listingUrl as a string
      } else if (typeof value === 'number' && isNaN(value)) {
        state[field] = 0;
      } else {
        state[field] = value;
      }
      console.log(`Field ${field} updated:`, state[field]);
    },
    CLEAR_CALCULATOR_FIELDS(state) {
      const fieldsToReset = [
        'address', 'address2', 'city', 'state', 'zip', 'showLoan', 'purchaseAmount',
        'downPaymentPer', 'interestRate', 'numYears', 'closeCosts', 'ownValue',
        'piPayment', 'dailyRate', 'occupancyRate', 'propTaxes', 'propIns', 'propElec',
        'propGas', 'propWater', 'propTrash', 'propInternet', 'propSupplies', 'propGifts',
        'propHOA', 'propCleaning', 'propAdv', 'propMgt', 'propRepairs', 'propOther',
        'depYears', 'impRatio', 'taxBracket'
      ];
      
      fieldsToReset.forEach(field => {
        state[field] = typeof state[field] === 'number' ? 0 : '';
      });
      
      state.propTaxes = 4000;
      state.propIns = 1500;
      state.propElec = 200;
      state.propGas = 100;
      state.propWater = 100;
      state.propTrash = 80;
      state.propInternet = 80;
      state.propSupplies = 100;
      state.propGifts = 50;
      state.propHOA = 0;
      state.propCleaning = 0;
      state.propAdv = 0;
      state.propMgt = 0;
      state.propRepairs = 0;
      state.propOther = 0;
      
      state.showLoan = true;
      state.depYears = 27.5;
      state.currentPropertyId = null;
      state.isNewProperty = true;
    },
    SET_CURRENT_PROPERTY_ID(state, id) {
      state.currentPropertyId = id;
    },
    SET_CURRENT_TAB(state, tab) {
      state.currentTab = tab;
    },
    SHOW_CONFIRMATION_MODAL(state, { title, message, onConfirm }) {
      state.confirmationModal = { show: true, title, message, onConfirm };
    },
    HIDE_CONFIRMATION_MODAL(state) {
      state.confirmationModal = { show: false, title: '', message: '', onConfirm: null };
    },
    SET_SHOW_AUTH_MODAL(state, value) {
      state.showAuthModal = value;
    },
    SHOW_MESSAGE_MODAL(state, { title, message }) {
      state.messageModal = { show: true, title, message };
    },
    HIDE_MESSAGE_MODAL(state) {
      state.messageModal = { show: false, title: '', message: '' };
    },
    FORCE_UPDATE(state) {
      // This mutation doesn't actually change anything, but it forces a reactivity update
      state.lastUpdate = Date.now();
    }
  },
  
  actions: {
    setUser({ commit }, user) {
      commit('SET_USER', user);
    },
    async updateUserProfile({ commit, state }, { field, value }) {
      if (!state.user) throw new Error('User not authenticated');

      try {
        if (field === 'username') {
          const isUnique = await auth.checkUsernameUniqueness(value);
          if (!isUnique) {
            throw new Error('Username is already taken');
          }
        }
        const updatedUser = await pb.collection('users').update(state.user.id, { [field]: value });
        commit('UPDATE_USER_PROFILE', { field, value });
        return updatedUser;
      } catch (error) {
        console.error('Error updating user profile:', error);
        throw error;
      }
    },
    async updateUserAvatar({ commit, state }, file) {
      if (!state.user) throw new Error('User not authenticated');

      try {
        const formData = new FormData();
        formData.append('userAvatar', file);

        const updatedUser = await pb.collection('users').update(state.user.id, formData);
        
        commit('SET_USER', updatedUser);
        
        return updatedUser;
      } catch (error) {
        console.error('Error updating user avatar:', error);
        throw error;
      }
    },
    async updateUserPassword({ state }, { oldPassword, newPassword }) {
      if (!state.user) throw new Error('User not authenticated');

      try {
        await pb.collection('users').update(state.user.id, {
          oldPassword: oldPassword,
          password: newPassword,
          passwordConfirm: newPassword
        });
        
        await auth.login(state.user.email, newPassword);

        return true;
      } catch (error) {
        console.error('Error updating user password:', error);
        throw error;
      }
    },
    async login({ commit }, { email, password }) {
      try {
        const authData = await auth.login(email, password);
        commit('SET_USER', authData.record);
        return authData;
      } catch (error) {
        console.error('Login failed:', error);
        throw error;
      }
    },
    async logout({ commit }) {
      await auth.logout();
      commit('SET_USER', null);
      commit('SET_CALCULATOR_RESULTS', []);
      commit('SET_CURRENT_PROPERTY_ID', null);
    },
    async register({ dispatch }, { username, email, password }) {
      try {
        const user = await auth.register(username, email, password);
        await dispatch('login', { email, password });
        return user;
      } catch (error) {
        console.error('Registration failed:', error);
        throw error;
      }
    },
    async fetchCalculatorResults({ commit, state }) {
      if (!state.user) return;
      try {
        const resultList = await pb.collection('calculator_results').getList(1, 50, {
          filter: `user="${pb.authStore.model.id}"`,
          sort: '-created',
        });
        commit('SET_CALCULATOR_RESULTS', resultList.items);
      } catch (error) {
        console.error('Error fetching calculator results:', error);
        throw error;
      }
    },
    saveGuestCalculation({ commit }, calculation) {
      commit('SET_GUEST_CALCULATION', calculation);
    },
    async saveNewProperty({ state, commit }) {
      if (!state.user) {
        throw new Error('User not authenticated');
      }
      if (!state.address.trim()) {
        throw new Error('Address is required');
      }
      
      try {
        const data = {
          user: pb.authStore.model.id,
          ...Object.fromEntries(
            Object.entries(state).filter(([key]) => 
              !['user', 'calculatorResults', 'currentPropertyId', 'currentTab', 'guestCalculation', 'confirmationModal', 'messageModal'].includes(key)
            )
          )
        };
        
        const savedProperty = await pb.collection('calculator_results').create(data);
        commit('ADD_CALCULATOR_RESULT', savedProperty);
        commit('SET_CURRENT_PROPERTY_ID', savedProperty.id);
        
        // Update all relevant fields in the state
        Object.keys(savedProperty).forEach(key => {
          if (key in state && !['id', 'user', 'created', 'updated'].includes(key)) {
            commit('updateField', { field: key, value: savedProperty[key] });
          }
        });
    
        // Set isNewProperty to false
        commit('SET_IS_NEW_PROPERTY', false);
    
        console.log('New property saved and state updated:', savedProperty);
        console.log('Listing URL saved:', savedProperty.listingUrl); // Add this line for explicit logging
    
        return savedProperty;
      } catch (error) {
        console.error('Error saving to PocketBase:', error);
        throw error;
      }
    },
    
    async updateExistingProperty({ state, commit }, propertyId) {
      if (!state.user) {
        throw new Error('User not authenticated');
      }
      if (!state.address.trim()) {
        throw new Error('Address is required');
      }
      if (!propertyId) {
        throw new Error('Property ID is required for update');
      }
      try {
        const data = Object.fromEntries(
          Object.entries(state).filter(([key]) => 
            !['user', 'calculatorResults', 'currentPropertyId', 'currentTab', 'guestCalculation', 'confirmationModal', 'messageModal'].includes(key)
          )
        );
        const updatedProperty = await pb.collection('calculator_results').update(propertyId, data);
        
        // Update the property in the calculatorResults array
        commit('UPDATE_CALCULATOR_RESULT', updatedProperty);
        
        // Update all relevant fields in the state
        Object.keys(updatedProperty).forEach(key => {
          if (key in state && !['id', 'user', 'created', 'updated'].includes(key)) {
            commit('updateField', { field: key, value: updatedProperty[key] });
          }
        });
    
        // Force a reactivity update
        commit('FORCE_UPDATE');
    
        console.log('Property updated successfully:', updatedProperty);
        console.log('Updated Listing URL:', updatedProperty.listingUrl);
    
        return updatedProperty;
      } catch (error) {
        console.error('Error updating property:', error);
        throw error;
      }
    },
    async deleteCalculatorResult({ commit, state }, id) {
      if (!state.user) {
        throw new Error('User not authenticated');
      }
      try {
        await pb.collection('calculator_results').delete(id);
        commit('REMOVE_CALCULATOR_RESULT', id);
      } catch (error) {
        console.error('Error deleting calculator result:', error);
        throw error;
      }
    },
    updateField({ commit }, payload) {
      commit('updateField', payload);
    },
    clearCalculatorFields({ commit }) {
      commit('CLEAR_CALCULATOR_FIELDS');
      commit('SET_CURRENT_PROPERTY_ID', null);
      commit('SET_IS_NEW_PROPERTY', true);
      commit('updateField', { field: 'listingUrl', value: '' });
    },
    setCurrentProperty({ commit }, propertyId) {
      commit('SET_CURRENT_PROPERTY_ID', propertyId);
    },
    setCurrentTab({ commit }, tab) {
      commit('SET_CURRENT_TAB', tab);
    },
    showConfirmationModal({ commit }, { title, message, onConfirm }) {
      commit('SHOW_CONFIRMATION_MODAL', { title, message, onConfirm });
    },
    showAuthModal({ commit }) {
      commit('SET_SHOW_AUTH_MODAL', true);
    },
    hideConfirmationModal({ commit }) {
      commit('HIDE_CONFIRMATION_MODAL');
    },
    showMessage({ commit }, { title, message }) {
      commit('SHOW_MESSAGE_MODAL', { title, message });
    },
    hideAuthModal({ commit }) {
      commit('SET_SHOW_AUTH_MODAL', false);
    },
    hideMessage({ commit }) {
      commit('HIDE_MESSAGE_MODAL');
    },
    async fetchPropertyById({ commit, state }, id) {
      if (state.currentPropertyId === id) return; // Add this line to prevent unnecessary fetches
      try {
        const property = await pb.collection('calculator_results').getOne(id);
        commit('UPDATE_CALCULATOR_RESULT', property);
        commit('SET_CURRENT_PROPERTY_ID', id);
        commit('SET_IS_NEW_PROPERTY', false);
        // Populate all fields
        Object.keys(property).forEach(key => {
          if (key in state) {
            commit('updateField', { field: key, value: property[key] });
          }
        });
      } catch (error) {
        console.error('Error fetching property:', error);
        commit('CLEAR_CALCULATOR_FIELDS');
        throw error;
      }
    },
  },

  getters: {
    isAuthenticated: (state) => !!state.user,
    currentUser: (state) => state.user,
    userFullName: (state) => state.user ? `${state.user.firstName} ${state.user.lastName}` : '',
    username: (state) => state.user ? state.user.username : '',
    userAvatar: (state) => {
      if (state.user && state.user.userAvatar) {
        return pb.getFileUrl(state.user, state.user.userAvatar);
      }
      return null;
    },
    getCalculatorResults: (state) => state.calculatorResults,
    getCalculatorResultById: (state) => (id) => {
      return state.calculatorResults.find(result => result.id === id);
    },
    getCurrentPropertyId: (state) => state.currentPropertyId,
    getCurrentProperty: (state) => {
      return state.calculatorResults.find(result => result.id === state.currentPropertyId) || null;
    },
    getCurrentTab: (state) => state.currentTab,
    getShowLoan: (state) => state.showLoan,
  }
})