<template>
  <div v-if="property" class="container mx-auto p-8 px-4 sm:px-6 lg:px-8">
    <h4 class="text-sm font-medium mb-2">This property was analyzed on <strong>NestInvestr</strong> and shared by {{ creatorUsername }}</h4>
    <div class="bg-emerald-100 rounded-md border-solid border border-emerald-600 drop-shadow-xl h-fit p-4">
      <h2 class="text font-bold flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-6 h-6 mr-2 flex-shrink-0">
          <path d="M14.916 2.404a.75.75 0 0 1-.32 1.011l-.596.31V17a1 1 0 0 1-1 1h-2.26a.75.75 0 0 1-.75-.75v-3.5a.75.75 0 0 0-.75-.75H6.75a.75.75 0 0 0-.75.75v3.5a.75.75 0 0 1-.75.75h-3.5a.75.75 0 0 1 0-1.5H2V9.957a.75.75 0 0 1-.596-1.372L2 8.275V5.75a.75.75 0 0 1 1.5 0v1.745l10.404-5.41a.75.75 0 0 1 1.012.319ZM15.861 8.57a.75.75 0 0 1 .736-.025l1.999 1.04A.75.75 0 0 1 18 10.957V16.5h.25a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1-.75-.75V9.21a.75.75 0 0 1 .361-.64Z" />
        </svg>
        <span class="leading-none">{{ property.address }}, {{ property.city }}, {{ property.state }}, {{ property.zip }}</span>
      </h2>
    </div>

    <div class="bg-white rounded-md border-solid border border-slate-300 drop-shadow-xl h-fit pt-0 px-8 pb-8 mt-4" id="results">
      <nav class="sm:mb-10 w-full py-6 md:py-0">
        <ul class="flex flex-wrap justify-center">
          <li v-for="tabName in displayTabs" :key="tabName" class="mr-2 w-full md:w-auto">
            <button
              @click="currentTab = tabName"
              class="inline-block p-1 px-4 md:p-4 text-sm font-medium transition-colors duration-200 ease-in-out border-l-2 md:border-b-2 md:border-l-0"
              :class="[
                currentTab === tabName 
                  ? 'text-emerald-600 border-emerald-600' 
                  : 'text-gray-500 border-transparent hover:text-gray-600 hover:border-gray-300'
              ]"
            >
              {{ tabName }}
            </button>
          </li>
        </ul>
      </nav>
      <article>
        <component 
          :is="currentTabComponent" 
          :property="reactiveProperty" 
          :key="`${property.id}-${currentTab}`"
        />
      </article>
    </div>
    
    <div class="mt-6">
      <button @click="toggleInputs" class="text-emerald-600 hover:text-emerald-800 font-medium">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-4 h-4 mr-1 inline">
          <path d="M17 2.75a.75.75 0 0 0-1.5 0v5.5a.75.75 0 0 0 1.5 0v-5.5ZM17 15.75a.75.75 0 0 0-1.5 0v1.5a.75.75 0 0 0 1.5 0v-1.5ZM3.75 15a.75.75 0 0 1 .75.75v1.5a.75.75 0 0 1-1.5 0v-1.5a.75.75 0 0 1 .75-.75ZM4.5 2.75a.75.75 0 0 0-1.5 0v5.5a.75.75 0 0 0 1.5 0v-5.5ZM10 11a.75.75 0 0 1 .75.75v5.5a.75.75 0 0 1-1.5 0v-5.5A.75.75 0 0 1 10 11ZM10.75 2.75a.75.75 0 0 0-1.5 0v1.5a.75.75 0 0 0 1.5 0v-1.5ZM10 6a2 2 0 1 0 0 4 2 2 0 0 0 0-4ZM3.75 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4ZM16.25 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z" />
        </svg>
        {{ showInputs ? 'Hide property inputs' : 'View property inputs (what led to these results)' }}
      </button>
      
      <div v-if="showInputs" ref="inputsSection" class="mt-4 bg-white rounded-md border-solid border border-slate-300 drop-shadow-xl h-fit p-6">
        <h3 class="text-2xl font-bold mb-6">Property Inputs</h3>
        
        <div v-for="(category, index) in groupedInputs" :key="category.name">
          <h4 class="text-xl font-semibold mb-4">{{ category.name }}</h4>
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
            <div v-for="item in category.items" :key="item.key" class="mb-2">
              <span class="font-medium">{{ item.label }}: </span> 
              <span v-if="item.type === 'currency'">{{ formatCurrency(item.value) }}</span>
              <span v-else-if="item.type === 'percentage'">{{ formatPercentage(item.value) }}</span>
              <span v-else>{{ item.value }}</span>
            </div>
          </div>
          <hr v-if="index < groupedInputs.length - 1" class="my-4 border-t border-gray-300">
        </div>
      </div>
    </div>
    
    <div v-if="isAuthenticated" class="mt-6">
      <button v-if="isOwner" @click="editProperty" class="bg-emerald-600 hover:bg-emerald-700 text-white font-bold py-2 px-4 rounded">
        Edit Property
      </button>
      <button v-else @click="cloneProperty" class="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        Clone Property
      </button>
    </div>
  </div>
  <div v-else-if="error" class="p-8 text-red-500">
    {{ error }}
  </div>
  <div v-else class="p-8">
    Loading property...
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch, reactive } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { pb } from '@/services/auth';
import { formatCurrency, formatPercent } from '@/utils/numberFormat';
import PurchaseAnalysis from './STRCalculator/results/PurchaseAnalysis.vue';
import ProjectedPL from './STRCalculator/results/ProjectedPL.vue';
import CashFlow from './STRCalculator/results/CashFlow.vue';
import TaxImpact from './STRCalculator/results/TaxImpact.vue';

const store = useStore();
const router = useRouter();
const route = useRoute();
const property = ref(null);
const reactiveProperty = reactive({});
const error = ref(null);
const creatorUsername = ref('Anonymous User');
const showInputs = ref(false);
const inputsSection = ref(null);

const tabs = ['Purchase Analysis', 'Projected P&L', 'Cash Flow', 'Tax Impact'];
const currentTab = ref('Purchase Analysis');

const isAuthenticated = computed(() => store.getters.isAuthenticated);
const currentUser = computed(() => store.getters.currentUser);
const isOwner = computed(() => 
  isAuthenticated.value && 
  currentUser.value && 
  property.value && 
  property.value.user === currentUser.value.id
);

const displayTabs = computed(() => {
  if (property.value && property.value.showLoan === false) {
    console.log('SharedProperty: Hiding Purchase Analysis tab');
    return tabs.filter(tab => tab !== 'Purchase Analysis');
  }
  console.log('SharedProperty: Showing all tabs');
  return tabs;
});

const currentTabComponent = computed(() => {
  switch (currentTab.value) {
    case 'Purchase Analysis': return PurchaseAnalysis;
    case 'Projected P&L': return ProjectedPL;
    case 'Cash Flow': return CashFlow;
    case 'Tax Impact': return TaxImpact;
    default: return null;
  }
});

const toggleInputs = () => {
  showInputs.value = !showInputs.value;
  if (showInputs.value) {
    setTimeout(() => {
      inputsSection.value?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  }
};

const formatLabel = (key) => {
  return key.replace(/([A-Z])/g, ' $1')
    .replace(/^./, function(str){ return str.toUpperCase(); })
    .replace(/Prop /g, '')
    .trim();
};

const formatPercentage = (value) => {
  return formatPercent(value);
};

const groupedInputs = computed(() => {
  if (!property.value) return [];

  const categories = [
    { 
      name: 'Property Info', 
      keys: ['address', 'address2', 'city', 'state', 'zip']
    },
    { 
      name: 'Ownership Status', 
      keys: [
        { key: 'showLoan', type: 'boolean' },
        { key: 'purchaseAmount', type: 'currency' },
        { key: 'downPaymentPer', type: 'percentage' },
        { key: 'interestRate', type: 'percentage' },
        { key: 'numYears', type: 'number' },
        { key: 'closeCosts', type: 'percentage' },
        { key: 'ownValue', type: 'currency' },
        { key: 'piPayment', type: 'currency' }
      ]
    },
    { 
      name: 'Income Information', 
      keys: [
        { key: 'dailyRate', type: 'currency' },
        { key: 'occupancyRate', type: 'percentage' }
      ]
    },
    { 
      name: 'Expense Information', 
      keys: [
        { key: 'propTaxes', type: 'currency' },
        { key: 'propIns', type: 'currency' },
        { key: 'propElec', type: 'currency' },
        { key: 'propGas', type: 'currency' },
        { key: 'propWater', type: 'currency' },
        { key: 'propTrash', type: 'currency' },
        { key: 'propInternet', type: 'currency' },
        { key: 'propSupplies', type: 'currency' },
        { key: 'propGifts', type: 'currency' },
        { key: 'propHOA', type: 'currency' },
        { key: 'propCleaning', type: 'currency' },
        { key: 'propAdv', type: 'currency' },
        { key: 'propMgt', type: 'percentage' },
        { key: 'propRepairs', type: 'percentage' },
        { key: 'propOther', type: 'currency' }
      ]
    },
    { 
      name: 'Tax Information', 
      keys: [
        { key: 'depYears', type: 'number' },
        { key: 'impRatio', type: 'percentage' },
        { key: 'taxBracket', type: 'percentage' }
      ]
    },
  ];

  console.log('Passing property to components:', JSON.stringify(reactiveProperty, null, 2));

  return categories.map(category => ({
    name: category.name,
    items: category.keys.map(key => {
      const itemKey = typeof key === 'string' ? key : key.key;
      const itemType = typeof key === 'string' ? 'text' : key.type;
      return {
        key: itemKey,
        label: formatLabel(itemKey),
        value: property.value[itemKey],
        type: itemType
      };
    }).filter(item => item.value !== undefined)
  }));
});

onMounted(async () => {
  const propertyId = route.params.id;

  if (isOwner.value) {
    store.dispatch('setCurrentTab', 'Calculator');
    router.push('/');
  }
  
  if (!propertyId) {
    error.value = 'No property ID provided. Please check the URL.';
    return;
  }

  try {
    console.log('Attempting to fetch property with ID:', propertyId);
    const record = await pb.collection('calculator_results').getOne(propertyId, {
      expand: 'user'
    });
    console.log('Fetched property:', record);
    property.value = record;
    
    // Update reactiveProperty
    Object.assign(reactiveProperty, record);
    
    // Ensure showLoan is a boolean
    reactiveProperty.showLoan = record.showLoan === false || record.showLoan === 'false' ? false : true;
    console.log('SharedProperty: showLoan value:', reactiveProperty.showLoan);
    
    // Set the creator's username
    if (record.expand && record.expand.user && record.expand.user.username) {
      creatorUsername.value = record.expand.user.username;
      console.log('Creator username set:', creatorUsername.value);
    } else {
      console.log('Username not found in expanded user data. Fetching separately.');
      try {
        const user = await pb.collection('users').getOne(record.user, {
          fields: 'username'
        });
        creatorUsername.value = user.username;
        console.log('Creator username set from separate fetch:', creatorUsername.value);
      } catch (userError) {
        console.error('Error fetching user data:', userError);
        creatorUsername.value = 'Anonymous User';
        console.log('Creator set to Anonymous User due to fetch error');
      }
    }
  } catch (err) {
    console.error('Error fetching property:', err);
    if (err.status === 404) {
      error.value = `Property not found (ID: ${propertyId}). Please check the URL and try again.`;
    } else {
      error.value = 'An error occurred while fetching the property. Please try again later.';
    }
  }
});

watch(() => reactiveProperty.showLoan, (newShowLoan) => {
  console.log('SharedProperty: showLoan changed to:', newShowLoan);
  if (newShowLoan === false) {
    if (currentTab.value === 'Purchase Analysis') {
      currentTab.value = displayTabs.value[0];
    }
  }
}, { immediate: true });

watch(() => reactiveProperty, (newValue) => {
  console.log('reactiveProperty updated:', newValue);
}, { deep: true });

const editProperty = () => {
  router.push(`/property/${property.value.id}`);
};

const cloneProperty = async () => {
  try {
    const clonedProperty = { ...property.value };
    delete clonedProperty.id;
    delete clonedProperty.user;
    const savedProperty = await store.dispatch('saveNewProperty', clonedProperty);
    router.push(`/property/${savedProperty.id}`);
  } catch (err) {
    console.error('Error cloning property:', err);
    error.value = 'Error cloning property. Please try again.';
  }
};
</script>