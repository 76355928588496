<template>
  <div>
    <!-- Depreciation Analysis Section -->
    <h3 class="text-xl font-semibold mb-4">Depreciation Analysis</h3>
    <div class="grid grid-cols-6 gap-4 mb-8">
      <div class="text-xl font-black col-start-1 col-end-6 sm:col-end-3">
        <span class="block text-base text-gray-400">Dwelling value</span>
        {{ formatCurrency(dwellingValue) }}
      </div>
      <div class="text-xl font-black col-start-1 col-end-6 sm:col-end-3">
        <span class="block text-base text-gray-400">Land value</span>
        {{ formatCurrency(landValue) }}
      </div>
      <div class="text-xl font-black col-start-1 sm:col-start-4 col-end-6">
        <span class="block text-base text-gray-400">Land as % of overall value</span>
        {{ formatPercent(landValuePer) }}
      </div>
      <div class="text-xl font-black sm:row-start-3 col-start-1 sm:col-start-4 col-end-6">
        <span class="block text-base text-gray-400">Years of depreciation</span>
        {{ formatNumber(props.property.depYears) }}
      </div>
      <div class="text-4xl sm:text-6xl font-black col-start-1 col-end-6 sm:col-end-3">
        <span class="block text-base text-gray-400">Annual depreciation</span>
        {{ formatCurrency(annualDepr) }}
      </div>
    </div>

    <!-- Tax Impact Section -->
    <h3 class="text-xl font-semibold mb-4">Tax Impact</h3>
    <div class="grid grid-cols-6 gap-4">
      <div class="text-xl font-black col-start-1 col-end-6 sm:col-end-4">
        <span class="block text-base text-gray-400">Net Operating Income (NOI):</span>
        {{ formatCurrency(netIncome) }}
      </div>
      <div class="text-xl font-black col-start-1 col-end-6 sm:col-end-4">
        <span class="block text-base text-gray-400">Total Mortgage Interest (Year 1):</span>
        ({{ formatCurrency(yearOneInt) }})
      </div>
      <div class="text-xl font-black col-start-1 col-end-6 sm:col-end-4 border-b pb-4">
        <span class="block text-base text-gray-400">Annual depreciation</span>
        ({{ formatCurrency(annualDepr) }})
      </div>
      <div class="text-xl font-black col-start-1 col-end-6 sm:col-end-4 border-b pb-4">
        <span class="block text-base text-gray-400">Taxable income</span>
        {{ formatCurrency(taxableIncome) }}
      </div>
      <div class="text-4xl sm:text-6xl font-black col-start-1 col-end-6 sm:col-end-4 whitespace-nowrap" :class="{ 'text-emerald-600': taxBenefit < 0, 'text-red-600': taxBenefit > 0 }">
        <span class="block text-base text-gray-400">Tax paid (+) or benefit (-)</span>
        {{ formatCurrency(taxBenefit) }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { formatCurrency, formatPercent, formatNumber } from '@/utils/numberFormat';
import * as formulajs from '@formulajs/formulajs';

const props = defineProps({
  property: {
    type: Object,
    required: true
  }
});

// Depreciation Analysis computations
const landValue = computed(() => {
  if (props.property.showLoan === 'true') {
    return props.property.purchaseAmount * (1 - props.property.impRatio / 100);
  } else {
    return props.property.ownValue * (1 - props.property.impRatio / 100);
  }
});

const landValuePer = computed(() => 1 - props.property.impRatio / 100);

const dwellingValue = computed(() => {
  if (props.property.showLoan === 'true') {
    return props.property.purchaseAmount * (props.property.impRatio / 100);
  } else {
    return props.property.ownValue * (props.property.impRatio / 100);
  }
});

// Tax Impact computations
const potentialIncome = computed(() => 365 * props.property.dailyRate);

const totalVacancies = computed(() => {
  return (1 - props.property.occupancyRate / 100) * potentialIncome.value;
});

const effGrossIncome = computed(() => potentialIncome.value - totalVacancies.value);

const totalExp = computed(() => {
  const annualExp = props.property.propTaxes + props.property.propIns;
  const monthlyExp = (props.property.propElec + props.property.propGas + props.property.propWater + 
    props.property.propTrash + props.property.propInternet + props.property.propSupplies + 
    props.property.propGifts + props.property.propHOA + props.property.propCleaning + 
    props.property.propAdv + props.property.propOther) * 12;
  const percentExp = (props.property.propMgt / 100 + props.property.propRepairs / 100) * effGrossIncome.value;
  return annualExp + monthlyExp + percentExp;
});

const netIncome = computed(() => effGrossIncome.value - totalExp.value);

const yearOneInt = computed(() => {
  if (props.property.interestRate > 0 && props.property.numYears > 0 && props.property.purchaseAmount > 0 && props.property.downPaymentPer > 0) {
    const loanAmount = props.property.purchaseAmount * (1 - props.property.downPaymentPer / 100);
    const negInt = formulajs.CUMIPMT(props.property.interestRate / 100 / 12, props.property.numYears * 12, loanAmount, 1, 12, 0);
    return Math.abs(negInt);
  } else {
    return 0;
  }
});

const annualDepr = computed(() => {
  if (props.property.showLoan === 'true' && dwellingValue.value > 0 && props.property.depYears > 0) {
    return dwellingValue.value / props.property.depYears;
  } else if (props.property.ownValue > 0 && props.property.depYears > 0) {
    return props.property.ownValue / props.property.depYears;
  } else {
    return 0;
  }
});

const taxableIncome = computed(() => netIncome.value - yearOneInt.value - annualDepr.value);

const taxBenefit = computed(() => {
  if (props.property.taxBracket > 0) {
    return taxableIncome.value * (props.property.taxBracket / 100) * (taxableIncome.value >= 0 ? 1 : -1);
  } else {
    return 0;
  }
});

// Console logs for debugging
console.log('TaxImpact - NOI:', netIncome.value);
console.log('TaxImpact - Year One Interest:', yearOneInt.value);
console.log('TaxImpact - Annual Depreciation:', annualDepr.value);
console.log('TaxImpact - Taxable Income:', taxableIncome.value);
console.log('TaxImpact - Tax Bracket:', props.property.taxBracket);
console.log('TaxImpact - Tax Benefit:', taxBenefit.value);
</script>