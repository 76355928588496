<template>
  <div class="grid grid-cols-6 gap-4">
    <div class="text-xl font-black col-start-1 col-end-6 sm:col-end-3">
      <span class="block text-base text-gray-400">Potential income</span>
      {{ formatCurrency(potentialIncome) }}
    </div>
    <div class="text-xl font-black col-start-1 col-end-6 sm:col-end-3 border-b pb-4">
      <span class="block text-base text-gray-400">Less vacancies</span>
      ({{ formatCurrency(totalVacancies) }}) <span class="block font-semibold text-sm italic">(@ {{ formatPercent(props.property.occupancyRate) }} occupancy)</span>
    </div>
    <div class="text-xl font-black col-start-1 col-end-6 sm:col-end-3">
      <span class="block text-base text-gray-400">Effective gross income</span>
      {{ formatCurrency(effGrossIncome) }}
    </div>
    <div class="text-xl font-black col-start-1 col-end-6 sm:col-end-3 sm:border-b sm:pb-4">
      <span class="block text-base text-gray-400">Less expenses</span>
      ({{ formatCurrency(totalExp) }})
    </div>
    <div class="text-xl font-black col-start-1 sm:col-start-4 col-end-6 border-b pb-4 sm:border-0 sm:pb-0">
      <span class="block text-base text-gray-400">Exp as % of gross income</span>
      {{ formatPercent(percOfExp) }}
    </div>
    <div class="text-4xl sm:text-6xl font-black col-start-1 col-end-6 sm:col-end-3 whitespace-nowrap" :class="{ 'text-emerald-600': netIncome > 0, 'text-red-600': netIncome < 0 }">
      <span class="block text-base text-gray-400">Net Operating Income (NOI)</span>
      {{ formatCurrency(netIncome) }}
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { formatCurrency, formatPercent } from '@/utils/numberFormat';

const props = defineProps({
  property: {
    type: Object,
    required: true
  }
});

const potentialIncome = computed(() => 365 * props.property.dailyRate);

const totalVacancies = computed(() => {
  return (1 - props.property.occupancyRate / 100) * potentialIncome.value;
});

const effGrossIncome = computed(() => potentialIncome.value - totalVacancies.value);

const totalExp = computed(() => {
  const annualExp = props.property.propTaxes + props.property.propIns;
  const monthlyExp = (props.property.propElec + props.property.propGas + props.property.propWater + 
    props.property.propTrash + props.property.propInternet + props.property.propSupplies + 
    props.property.propGifts + props.property.propHOA + props.property.propCleaning + 
    props.property.propAdv + props.property.propOther) * 12;
  const percentExp = (props.property.propMgt / 100 + props.property.propRepairs / 100) * effGrossIncome.value;
  return annualExp + monthlyExp + percentExp;
});

const percOfExp = computed(() => {
  return effGrossIncome.value > 0 ? totalExp.value / effGrossIncome.value : 0;
});

const netIncome = computed(() => effGrossIncome.value - totalExp.value);

// Log values for debugging
console.log('ProjectedPL - NOI:', netIncome.value);
</script>