<template>
  <nav class="bg-white py-2 border-b border-slate-200 drop-shadow-md relative z-100">
    <div class="container mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center">
      <router-link to="/" class="text-slate-900 text-2xl font-bold font-serif">
        <img src="@/assets/img/nestinvestr-logo.svg" class="h-6 md:h-8 w-auto" alt="">
      </router-link>
      <div class="flex items-center space-x-6 text-sm font-semibold">
        <router-link to="/" class="text-slate-500 hover:text-emerald-600">Calculator</router-link>
        <div v-if="isAuthenticated" class="relative">
          <button 
            @click="toggleDropdown" 
            class="w-8 h-8 rounded-full bg-emerald-500 flex items-center justify-center text-white font-bold focus:outline-none overflow-hidden"
            ref="avatarButton"
          >
            <img 
              v-if="userAvatar" 
              :src="userAvatar" 
              alt="User avatar" 
              class="w-full h-full object-cover"
            >
            <span v-else>{{ userInitials }}</span>
          </button>
          <div v-if="showDropdown" class="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-120">
            <router-link to="/profile" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" @click="closeDropdown">Profile</router-link>
            <button @click="logout" class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Logout</button>
          </div>
        </div>
        <button 
          v-else 
          @click="showAuthModal"
          class="text-slate-500 hover:text-emerald-600"
        >
          Login
        </button>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

const store = useStore();
const router = useRouter();
const route = useRoute();

const isAuthenticated = computed(() => store.getters.isAuthenticated);
const currentUser = computed(() => store.getters.currentUser);
const userAvatar = computed(() => store.getters.userAvatar);
const avatarButton = ref(null);

const showDropdown = ref(false);

const userInitials = computed(() => {
  if (currentUser.value && currentUser.value.email) {
    return currentUser.value.email.slice(0, 2).toUpperCase();
  }
  return '';
});

const toggleDropdown = () => {
  showDropdown.value = !showDropdown.value;
};

const closeDropdown = () => {
  showDropdown.value = false;
};

const logout = async () => {
  try {
    await store.dispatch('logout');
    router.push('/');
    closeDropdown();
  } catch (error) {
    console.error('Logout failed:', error);
  }
};

const showAuthModal = () => {
  store.dispatch('showAuthModal');
};


const handleClickOutside = (event) => {
  if (avatarButton.value && !avatarButton.value.contains(event.target)) {
    closeDropdown();
  }
};

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});

watch(() => route.fullPath, () => {
  closeDropdown();
});

</script>
