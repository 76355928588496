<template>
  <div>
    <div class="mb-4">
      <label class="block text-sm font-medium text-gray-700">Depreciation years</label>
      <input 
        :value="depYears"
        @input="updateField('depYears', $event.target.value)"
        type="number"
        step="0.1"
        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
      />
      <p class="mt-2 text-xs">Years at which your property will be depreciated. Standard is 27.5 years.</p>
    </div>
    <div class="mb-4">
      <label class="block text-sm font-medium text-gray-700">Improvement ratio</label>
      <div class="mt-1 relative rounded-md shadow-sm">
        <input 
          :value="impRatio"
          @input="updateField('impRatio', $event.target.value)"
          type="number"
          step="0.1"
          class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
        />
        <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <span class="text-gray-500 sm:text-sm">%</span>
        </div>
      </div>
      <p class="mt-2 text-xs">What % of the property's value is from the improvements? For example, if the total value is $100 and the home is worth $90, the ratio is 90%.</p>
    </div>
    <div class="mb-4">
      <label class="block text-sm font-medium text-gray-700">Investor's tax bracket</label>
      <div class="mt-1 relative rounded-md shadow-sm">
        <input 
          :value="taxBracket"
          @input="updateField('taxBracket', $event.target.value)"
          type="number"
          step="0.1"
          class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
        />
        <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <span class="text-gray-500 sm:text-sm">%</span>
        </div>
      </div>
      <p class="mt-2 text-xs">What overall estimated tax bracket is the owner in? This helps determine the tax impact of this rental.</p>
    </div>
  </div>
</template>

<script setup>
import { computed, watch } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const emit = defineEmits(['section-completed']);

const depYears = computed(() => store.state.depYears);
const impRatio = computed(() => store.state.impRatio);
const taxBracket = computed(() => store.state.taxBracket);

const updateField = (field, value) => {
  store.commit('updateField', { field, value: Number(value) });
};

watch([depYears, impRatio, taxBracket], ([newDepYears, newImpRatio, newTaxBracket]) => {
  if (newDepYears && newImpRatio && newTaxBracket) {
    emit('section-completed');
  }
});
</script>