<!-- src/components/ConfirmationModal.vue -->
<template>
  <div v-if="show" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center z-50">
    <div class="bg-white p-5 rounded-lg shadow-xl max-w-sm w-full">
      <h2 class="text-xl font-semibold mb-4">{{ title }}</h2>
      <p class="mb-6">{{ message }}</p>
      <div class="flex justify-end space-x-4">
        <button 
          @click="cancel" 
          class="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300"
        >
          Cancel
        </button>
        <button 
          @click="confirm" 
          class="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400"
        >
          Confirm
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const show = computed(() => store.state.confirmationModal.show);
const title = computed(() => store.state.confirmationModal.title);
const message = computed(() => store.state.confirmationModal.message);

const confirm = () => {
  if (store.state.confirmationModal.onConfirm) {
    store.state.confirmationModal.onConfirm();
  }
  store.dispatch('hideConfirmationModal');
};

const cancel = () => {
  store.dispatch('hideConfirmationModal');
};
</script>