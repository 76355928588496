<template>
  <div>
    <div class="mb-4">
      <p class="italic text-xs mb-2">Pre-populated with basic expenses. Replace with your estimates for more accurate results.</p>
      <label class="block text-sm font-medium text-gray-700">Property taxes (yearly)</label>
      <div class="mt-1 relative rounded-md shadow-sm">
        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span class="text-gray-500 sm:text-sm">$</span>
        </div>
        <input 
          :value="propTaxes"
          @input="updateField('propTaxes', $event.target.value)"
          type="number"
          step="0.01"
          class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
        />
      </div>
    </div>

    <div class="mb-4">
      <label class="block text-sm font-medium text-gray-700">Insurance (yearly)</label>
      <div class="mt-1 relative rounded-md shadow-sm">
        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span class="text-gray-500 sm:text-sm">$</span>
        </div>
        <input 
          :value="propIns"
          @input="updateField('propIns', $event.target.value)"
          type="number"
          step="0.01"
          class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
        />
      </div>
    </div>

    <div class="mb-4">
      <label class="block text-sm font-medium text-gray-700">Electricity (monthly)</label>
      <div class="mt-1 relative rounded-md shadow-sm">
        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span class="text-gray-500 sm:text-sm">$</span>
        </div>
        <input 
          :value="propElec"
          @input="updateField('propElec', $event.target.value)"
          type="number"
          step="0.01"
          class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
        />
      </div>
    </div>

    <div class="mb-4">
      <label class="block text-sm font-medium text-gray-700">Gas (monthly)</label>
      <div class="mt-1 relative rounded-md shadow-sm">
        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span class="text-gray-500 sm:text-sm">$</span>
        </div>
        <input 
          :value="propGas"
          @input="updateField('propGas', $event.target.value)"
          type="number"
          step="0.01"
          class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
        />
      </div>
    </div>

    <div class="mb-4">
      <label class="block text-sm font-medium text-gray-700">Water/Sewer (monthly)</label>
      <div class="mt-1 relative rounded-md shadow-sm">
        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span class="text-gray-500 sm:text-sm">$</span>
        </div>
        <input 
          :value="propWater"
          @input="updateField('propWater', $event.target.value)"
          type="number"
          step="0.01"
          class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
        />
      </div>
    </div>

    <div class="mb-4">
      <label class="block text-sm font-medium text-gray-700">Trash (monthly)</label>
      <div class="mt-1 relative rounded-md shadow-sm">
        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span class="text-gray-500 sm:text-sm">$</span>
        </div>
        <input 
          :value="propTrash"
          @input="updateField('propTrash', $event.target.value)"
          type="number"
          step="0.01"
          class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
        />
      </div>
    </div>

    <div class="mb-4">
      <label class="block text-sm font-medium text-gray-700">Internet (monthly)</label>
      <div class="mt-1 relative rounded-md shadow-sm">
        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span class="text-gray-500 sm:text-sm">$</span>
        </div>
        <input 
          :value="propInternet"
          @input="updateField('propInternet', $event.target.value)"
          type="number"
          step="0.01"
          class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
        />
      </div>
    </div>

    <div class="mb-4">
      <label class="block text-sm font-medium text-gray-700">Supplies (monthly)</label>
      <div class="mt-1 relative rounded-md shadow-sm">
        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span class="text-gray-500 sm:text-sm">$</span>
        </div>
        <input 
          :value="propSupplies"
          @input="updateField('propSupplies', $event.target.value)"
          type="number"
          step="0.01"
          class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
        />
      </div>
    </div>

    <div class="mb-4">
      <label class="block text-sm font-medium text-gray-700">Gifts (monthly)</label>
      <div class="mt-1 relative rounded-md shadow-sm">
        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span class="text-gray-500 sm:text-sm">$</span>
        </div>
        <input 
          :value="propGifts"
          @input="updateField('propGifts', $event.target.value)"
          type="number"
          step="0.01"
          class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
        />
      </div>
    </div>

    <div class="mb-4">
      <label class="block text-sm font-medium text-gray-700">HOA dues (monthly)</label>
      <div class="mt-1 relative rounded-md shadow-sm">
        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span class="text-gray-500 sm:text-sm">$</span>
        </div>
        <input 
          :value="propHOA"
          @input="updateField('propHOA', $event.target.value)"
          type="number"
          step="0.01"
          class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
        />
      </div>
    </div>

    <div class="mb-4">
      <label class="block text-sm font-medium text-gray-700">Extra cleaning (monthly)</label>
      <div class="mt-1 relative rounded-md shadow-sm">
        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span class="text-gray-500 sm:text-sm">$</span>
        </div>
        <input 
          :value="propCleaning"
          @input="updateField('propCleaning', $event.target.value)"
          type="number"
          step="0.01"
          class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
        />
      </div>
      <p class="mt-2 text-xs">Only include cleaning costs above traditional cleaning fees, unless you will not charge renters directly.</p>
    </div>

    <div class="mb-4">
      <label class="block text-sm font-medium text-gray-700">Advertising (monthly)</label>
      <div class="mt-1 relative rounded-md shadow-sm">
        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span class="text-gray-500 sm:text-sm">$</span>
        </div>
        <input 
          :value="propAdv"
          @input="updateField('propAdv', $event.target.value)"
          type="number"
          step="0.01"
          class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
        />
      </div>
    </div>

    <div class="mb-4">
      <label class="block text-sm font-medium text-gray-700">Management (% of income)</label>
      <div class="mt-1 relative rounded-md shadow-sm">
        <input 
          :value="propMgt"
          @input="updateField('propMgt', $event.target.value)"
          type="number"
          step="0.1"
          class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
        />
        <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <span class="text-gray-500 sm:text-sm">%</span>
        </div>
      </div>
    </div>

    <div class="mb-4">
      <label class="block text-sm font-medium text-gray-700">Repairs/Maintenance (% of income)</label>
      <div class="mt-1 relative rounded-md shadow-sm">
        <input 
          :value="propRepairs"
          @input="updateField('propRepairs', $event.target.value)"
          type="number"
          step="0.1"
          class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
        />
        <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <span class="text-gray-500 sm:text-sm">%</span>
        </div>
      </div>
    </div>
    
    <div class="mb-4">
      <label class="block text-sm font-medium text-gray-700">Other (monthly)</label>
      <div class="mt-1 relative rounded-md shadow-sm">
        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span class="text-gray-500 sm:text-sm">$</span>
        </div>
        <input 
          :value="propOther"
          @input="updateField('propOther', $event.target.value)"
          type="number"
          step="0.01"
          class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, watch } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const emit = defineEmits(['section-completed']);

const propTaxes = computed(() => store.state.propTaxes);
const propIns = computed(() => store.state.propIns);
const propElec = computed(() => store.state.propElec);
const propGas = computed(() => store.state.propGas);
const propWater = computed(() => store.state.propWater);
const propTrash = computed(() => store.state.propTrash);
const propInternet = computed(() => store.state.propInternet);
const propSupplies = computed(() => store.state.propSupplies);
const propGifts = computed(() => store.state.propGifts);
const propHOA = computed(() => store.state.propHOA);
const propCleaning = computed(() => store.state.propCleaning);
const propAdv = computed(() => store.state.propAdv);
const propMgt = computed(() => store.state.propMgt);
const propRepairs = computed(() => store.state.propRepairs);
const propOther = computed(() => store.state.propOther);

const updateField = (field, value) => {
  store.commit('updateField', { field, value: Number(value) });
};

watch([propTaxes, propIns], ([newPropTaxes, newPropIns]) => {
  if (newPropTaxes && newPropIns) {
    emit('section-completed');
  }
});
</script>