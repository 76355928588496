import PocketBase from 'pocketbase';

export const pb = new PocketBase(process.env.VUE_APP_POCKETBASE_URL);

export const auth = {
  async register(username, email, password) {
    try {
      const user = await pb.collection('users').create({
        username,
        email,
        password,
        passwordConfirm: password,
      });
      console.log('User created:', user);
      return user;
    } catch (error) {
      console.error('Registration error:', error);
      if (error.data) {
        console.error('Error details:', error.data);
      }
      throw error;
    }
  },

  async login(email, password) {
    const authData = await pb.collection('users').authWithPassword(email, password);
    console.log('Auth data after login:', authData);
    return authData;
  },

  async logout() {
    pb.authStore.clear();
  },

  isAuthenticated() {
    console.log('Checking auth state:', pb.authStore.isValid);
    return pb.authStore.isValid;
  },

  getCurrentUser() {
    console.log('Getting current user:', pb.authStore.model);
    return pb.authStore.model;
  },

  async getProperty(id) {
    try {
      return await pb.collection('calculator_results').getOne(id);
    } catch (err) {
      console.error('Error fetching property:', err);
      throw err;
    }
  },

  async checkUsernameUniqueness(username) {
    try {
      const result = await pb.collection('users').getList(1, 1, {
        filter: `username = "${username}"`
      });
      return result.totalItems === 0;
    } catch (err) {
      console.error('Error checking username uniqueness:', err);
      throw err;
    }
  }
};