<template>
  <div class="grid grid-cols-6 gap-6">
    <div v-if="props.property.listingUrl" class="col-span-6 mb-4">
      <span class="block text-base text-gray-400">Listing URL</span>
      <a :href="props.property.listingUrl" target="_blank" rel="noopener noreferrer" class="text-blue-600 hover:text-blue-800 break-all">
        {{ props.property.listingUrl }}
      </a>
    </div>
  </div>
  <div class="grid grid-cols-6 gap-6">
    <div class="text-3xl sm:text-4xl font-black col-start-1 col-end-6 sm:col-end-3 whitespace-nowrap">
      <span class="block text-base text-gray-400">Loan amount</span>
      {{ formatCurrency(findLoan) }}
    </div>
    <div class="text-xl font-black col-start-1 col-end-6 sm:col-end-3 whitespace-nowrap">
      <span class="block text-base text-gray-400">Down payment</span>
      {{ formatCurrency(downPaymentDol) }} <span class="font-semibold text-sm italic">({{ formatPercent(props.property.downPaymentPer) }})</span>
    </div>
    <div class="text-xl font-black col-start-1 col-end-6 sm:col-end-3">
      <span class="block text-base text-gray-400">Closing costs</span>
      {{ formatCurrency(findClose) }} <span class="font-semibold text-sm italic">({{ formatPercent(props.property.closeCosts) }})</span>
    </div>
    <div class="text-3xl sm:text-4xl font-black sm:row-start-1 col-start-1 sm:col-start-4 col-end-6 pt-6 border-t sm:pt-0 sm:border-t-0">
      <span class="block text-base text-gray-400">Monthly P&I payment</span>
      {{ formatCurrency(findMonth) }}
    </div>
    <div class="text-xl font-black sm:row-start-2 col-start-1 sm:col-start-4 col-end-6">
      <span class="block text-base text-gray-400">Yearly P&I payments</span>
      {{ formatCurrency(findYear) }}
    </div>
    <div class="text-xl font-black sm:row-start-3 col-start-1 sm:col-start-4 col-end-6">
      <span class="block text-base text-gray-400">Loan term</span>
      {{ formatNumber(props.property.numYears) }} years at {{ formatPercent(props.property.interestRate) }}
    </div>
    <div class="text-4xl sm:text-6xl font-black col-start-1 col-end-6 sm:col-end-3 pt-6 border-t">
      <span class="block text-base text-gray-400">Cash to close</span>
      {{ formatCurrency(cashToClose) }}
    </div>
  </div>
</template>

<script setup>
import { computed, watch, onMounted, onUpdated, reactive, toRefs, isReactive } from 'vue';
import { formatCurrency, formatPercent, formatNumber } from '@/utils/numberFormat';

const props = defineProps({
  property: {
    type: Object,
    required: true
  }
});

// Check if the property is reactive, if not, make it reactive
const reactiveProperty = isReactive(props.property) ? props.property : reactive(props.property);

const { 
  purchaseAmount, 
  downPaymentPer, 
  interestRate, 
  numYears, 
  closeCosts
} = toRefs(reactiveProperty);

const findLoan = computed(() => {
  const loan = purchaseAmount.value - downPaymentDol.value;
  return isNaN(loan) ? 0 : loan;
});

const downPaymentDol = computed(() => {
  const amount = (downPaymentPer.value / 100) * purchaseAmount.value;
  return isNaN(amount) ? 0 : amount;
});

const findClose = computed(() => {
  const closingCosts = purchaseAmount.value * (closeCosts.value / 100);
  return isNaN(closingCosts) ? 0 : closingCosts;
});

const findMonth = computed(() => {
  if (numYears.value > 0 && interestRate.value > 0) {
    const p = findLoan.value;
    const r = interestRate.value / 1200;
    const n = numYears.value * 12;
    const monthlyPayment = (p * r * Math.pow(1 + r, n)) / (Math.pow(1 + r, n) - 1);
    return isNaN(monthlyPayment) ? 0 : monthlyPayment;
  }
  return 0;
});

const findYear = computed(() => findMonth.value * 12);

const cashToClose = computed(() => downPaymentDol.value + findClose.value);

watch(() => props.property, (newVal) => {
  console.log('Property updated in PurchaseAnalysis:', newVal);
}, { deep: true });

onMounted(() => {
  console.log('PurchaseAnalysis mounted, initial property:', props.property);
});

onUpdated(() => {
  console.log('PurchaseAnalysis updated, current property:', props.property);
});

// Debugging computed properties
watch(findLoan, (newVal) => console.log('findLoan updated:', newVal));
watch(downPaymentDol, (newVal) => console.log('downPaymentDol updated:', newVal));
watch(findClose, (newVal) => console.log('findClose updated:', newVal));
watch(findMonth, (newVal) => console.log('findMonth updated:', newVal));
watch(findYear, (newVal) => console.log('findYear updated:', newVal));
watch(cashToClose, (newVal) => console.log('cashToClose updated:', newVal));
</script>