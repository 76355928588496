<template>
  <div class="z-0">
    <div v-if="displayProperty" class="bg-white rounded-md border-solid border border-slate-300 drop-shadow-xl h-fit pt-0 px-8 pb-8" id="results">
      <nav class="sm:mb-10 w-full py-6 md:py-0">
        <ul class="flex flex-wrap justify-center">
          <li v-for="tabName in displayTabs" :key="tabName" class="mr-2 w-full md:w-auto">
            <button
              @click="currentTab = tabName"
              class="inline-block p-1 px-4 md:p-4 text-sm font-medium transition-colors duration-200 ease-in-out border-l-2 md:border-b-2 md:border-l-0"
              :class="[
                currentTab === tabName 
                  ? 'text-emerald-600 border-emerald-600' 
                  : 'text-gray-500 border-transparent hover:text-gray-600 hover:border-gray-300'
              ]"
            >
              {{ tabName }}
            </button>
          </li>
        </ul>
      </nav>
      <article>
        <component 
          :is="currentTabComponent" 
          :property="displayProperty" 
          :key="`${currentPropertyId || 'new'}-${forceRerender}`"
        />
        
      </article>
    </div>
    <p v-else-if="isEditable" class="text-gray-500 mt-2">No property selected. Please select a property or enter new property details.</p>
    <p v-else class="text-gray-500 mt-2">Property data not available.</p>
    <ErrorModal 
      :show="!!error" 
      :message="error"
      @close="error = ''"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onUpdated } from 'vue';
import { useStore } from 'vuex';
import { reactive } from 'vue';
import PurchaseAnalysis from './results/PurchaseAnalysis.vue';
import ProjectedPL from './results/ProjectedPL.vue';
import CashFlow from './results/CashFlow.vue';
import TaxImpact from './results/TaxImpact.vue';
import ErrorModal from '../ErrorModal.vue';

const store = useStore();
const forceRerender = ref(0);
const error = ref('');
const isSaved = ref(false);
const hasUnsavedChanges = ref(false);

const tabs = ['Purchase Analysis', 'Projected P&L', 'Cash Flow', 'Tax Impact'];
const currentTab = ref('Purchase Analysis');

const showLoan = computed(() => store.state.showLoan);
const currentPropertyId = computed(() => store.state.currentPropertyId);
const isAuthenticated = computed(() => store.getters.isAuthenticated);

const displayTabs = computed(() => {
  console.log('Computing displayTabs, showLoan:', showLoan.value);
  return showLoan.value === false
    ? tabs.filter(tab => tab !== 'Purchase Analysis')
    : tabs;
});

const currentTabComponent = computed(() => {
  switch (currentTab.value) {
    case 'Purchase Analysis': return PurchaseAnalysis;
    case 'Projected P&L': return ProjectedPL;
    case 'Cash Flow': return CashFlow;
    case 'Tax Impact': return TaxImpact;
    default: return null;
  }
});


const displayProperty = computed(() => {
  if (currentPropertyId.value) {
    const currentProperty = store.getters.getCurrentProperty;
    // Use reactive to ensure the object is reactive
    return reactive({ ...store.state, ...currentProperty });
  }
  return reactive({ ...store.state });
});

watch(currentPropertyId, (newId) => {
  console.log('Current Property ID changed:', newId);
  if (newId) {
    store.dispatch('fetchPropertyById', newId);
  }
});



watch(() => store.state, () => {
  console.log('Store state changed, updating display property');
  if (isSaved.value) {
    hasUnsavedChanges.value = true;
    isSaved.value = false;
  }
}, { deep: true });

watch(() => displayProperty.value, () => {
  forceRerender.value += 1;
  console.log('Display Property updated:', displayProperty.value);
}, { deep: true });

watch(showLoan, (newShowLoan) => {
  console.log('showLoan changed:', newShowLoan);
  if (newShowLoan === false && currentTab.value === 'Purchase Analysis') {
    currentTab.value = displayTabs.value[0];
  }
});


watch(isAuthenticated, (newValue) => {
  console.log('Authentication state changed:', newValue);
});

onMounted(() => {
  console.log('ResultsDisplay mounted, showLoan:', showLoan.value);
  console.log('Initial displayProperty:', displayProperty.value);
});

onUpdated(() => {
  console.log('ResultsDisplay updated, displayProperty:', displayProperty.value);
});
</script>