<template>
  <div id="app">
    <NavigationBar />
    <router-view></router-view>

    <!-- Modals -->
    <AuthModal 
      v-if="showAuthModal"
      @close="hideAuthModal"
    />
    <MessageModal 
      :show="!!message"
      :title="message?.title"
      :message="message?.message"
      @close="clearMessage"
    />
    <ConfirmationModal />
  </div>
</template>

<script>
import { onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { auth } from '@/services/auth';
import NavigationBar from '@/components/NavigationBar.vue';
import AuthModal from './components/AuthModal.vue';
import MessageModal from './components/MessageModal.vue';
import ConfirmationModal from './components/ConfirmationModal.vue';

export default {
  name: 'App',
  components: {
    NavigationBar,
    MessageModal,
    ConfirmationModal,
    AuthModal
  },
  setup() {
    const store = useStore();

    const message = computed(() => store.state.message);
    const showAuthModal = computed(() => store.state.showAuthModal);

    const hideAuthModal = () => {
      store.dispatch('hideAuthModal');
    };

    const clearMessage = () => {
      store.commit('CLEAR_MESSAGE');
    };

    onMounted(() => {
      if (auth.isAuthenticated()) {
        store.dispatch('setUser', auth.getCurrentUser());
      }
    });

    return {
      message,
      clearMessage,
      showAuthModal,
      hideAuthModal
    };
  },
  mounted() {
    const link = document.createElement('link');
    link.rel = 'icon';
    link.href = process.env.BASE_URL + 'nestinvestr-favicon.svg';
    link.type = 'image/svg+xml';
    document.head.appendChild(link);
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>