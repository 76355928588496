<template>
  <div class="grid grid-cols-6 gap-4">
    <div class="text-xl font-black col-start-1 col-end-6 sm:col-end-3">
      <span class="block text-base text-gray-400">Net Operating Income (NOI)</span>
      {{ formatCurrency(netOperatingIncome) }}
    </div>
    <div class="text-xl font-black col-start-1 col-end-6 sm:col-end-3">
      <span class="block text-base text-gray-400">Less: Annual Mortgage Payment</span>
      ({{ formatCurrency(annualMortgagePayment) }})
    </div>
    <div class="text-4xl sm:text-6xl font-black col-start-1 col-end-6 sm:col-end-3 whitespace-nowrap" :class="{ 'text-emerald-600': cashFlow > 0, 'text-red-600': cashFlow < 0 }">
      <span class="block text-base text-gray-400">Cash Flow</span>
      {{ formatCurrency(cashFlow) }}
    </div>
    <div class="text-xl sm:text-xl font-black col-start-1 sm:col-start-4 col-end-6 whitespace-nowrap">
      <span class="block text-base text-gray-400">Occupancy rate needed to breakeven</span>
      {{ formatPercentage(breakEvenOccupancy) }}
      <span class="font-semibold text-sm italic">({{ breakEvenDays }} days)</span>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { formatCurrency, formatPercent } from '@/utils/numberFormat';

const props = defineProps({
  property: {
    type: Object,
    required: true
  }
});

const potentialIncome = computed(() => 365 * props.property.dailyRate);

const totalVacancies = computed(() => {
  return (1 - props.property.occupancyRate / 100) * potentialIncome.value;
});

const effGrossIncome = computed(() => potentialIncome.value - totalVacancies.value);

const totalExp = computed(() => {
  const annualExp = props.property.propTaxes + props.property.propIns;
  const monthlyExp = (props.property.propElec + props.property.propGas + props.property.propWater + 
    props.property.propTrash + props.property.propInternet + props.property.propSupplies + 
    props.property.propGifts + props.property.propHOA + props.property.propCleaning + 
    props.property.propAdv + props.property.propOther) * 12;
  const percentExp = (props.property.propMgt / 100 + props.property.propRepairs / 100) * effGrossIncome.value;
  return annualExp + monthlyExp + percentExp;
});

const netOperatingIncome = computed(() => effGrossIncome.value - totalExp.value);

const annualMortgagePayment = computed(() => {
  const payment = props.property.piPayment || 0;
  return payment * 12;
});

const cashFlow = computed(() => netOperatingIncome.value - annualMortgagePayment.value);

const breakEvenOccupancy = computed(() => {
  const totalAnnualExpenses = totalExp.value + annualMortgagePayment.value;
  if (props.property.dailyRate === 0) return 0;
  const breakEvenDays = totalAnnualExpenses / props.property.dailyRate;
  return Math.min(breakEvenDays / 365, 1);
});

const breakEvenDays = computed(() => {
  return Math.round(breakEvenOccupancy.value * 365);
});

const formatPercentage = (value) => {
  return formatPercent(value * 100);
};
</script>