// src/main.js
import { createApp } from 'vue'
import App from './App.vue'
import './index.css'
import { createI18n } from "vue-i18n"
import store from './store'
import router from './router'

document.body.classList.add('bg-stone-100')

const i18n = createI18n({
  locale: 'en',
  globalInjection: true,
  allowComposition: true,
  numberFormats: {
    en: {
        currency: {
            style: 'currency', currency: 'USD'
        },
        decimal: {
          style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
        },
        percent: {
          style: 'percent', useGrouping: false
        }
    }
  }
});

createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .mount("#app");