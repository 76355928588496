<template>
  <div class="mb-6 border rounded-lg overflow-hidden">
    <button
      @click="toggle"
      class="w-full flex justify-between items-center py-2 px-4 bg-slate-100 hover:bg-slate-200 transition-colors duration-200"
    >
      <p class="text-lg font-semibold">{{ title }}</p>
      <span class="transform transition-transform duration-200" :class="{ 'rotate-180': isExpanded }">
        ▼
      </span>
    </button>
    <div v-show="isExpanded" class="p-4">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
defineProps({
  title: {
    type: String,
    required: true
  },
  isExpanded: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['toggle']);

const toggle = () => {
  emit('toggle');
};
</script>