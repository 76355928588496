<template>
  <div>
    <div class="mb-4">
      <label class="block text-sm font-medium text-gray-700">Do you own this property?</label>
      <div class="mt-2">
        <label class="inline-flex items-center mr-6">
          <input 
            class="form-check-input form-check-input appearance-none rounded-full h-2 w-2 border border-gray-300 bg-white checked:bg-emerald-600 focus:outline-none transition duration-200 mt-1 mr-2 align-middle float-left cursor-pointer" 
            type="radio" 
            :value="true"
            v-model="showLoanLocal"
          >
          <span class="ml-2">I'm purchasing it</span>
        </label>
        <label class="inline-flex items-center">
          <input 
            class="form-check-input form-check-input appearance-none rounded-full h-2 w-2 border border-gray-300 bg-white checked:bg-emerald-600 checked:border-emerald-600 focus:outline-none transition duration-200 mt-1 mr-2 align-middle float-left cursor-pointer" 
            type="radio" 
            :value="false"
            v-model="showLoanLocal"
          >
          <span class="ml-2">I own it</span>
        </label>
      </div>
    </div>
    
    <template v-if="showLoanLocal">
      <div class="mb-4">
        <label class="block text-sm font-medium text-gray-700">Listing URL (optional)</label>
        <input 
          v-model="listingUrlLocal"
          type="url"
          placeholder="https://example.com/listing"
          class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        />
      </div>
      <div class="mb-4">
        <label class="block text-sm font-medium text-gray-700">Purchase amount</label>
        <div class="mt-1 relative rounded-md shadow-sm">
          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span class="text-gray-500 sm:text-sm">$</span>
          </div>
          <input 
            :value="purchaseAmount"
            @input="updateField('purchaseAmount', $event.target.value)"
            type="number"
            class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
          />
        </div>
      </div>
      <div class="mb-4">
        <label class="block text-sm font-medium text-gray-700">Down payment</label>
        <div class="mt-1 relative rounded-md shadow-sm">
          <input 
            :value="downPaymentPer"
            @input="updateField('downPaymentPer', $event.target.value)"
            type="number"
            class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
          />
          <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <span class="text-gray-500 sm:text-sm">%</span>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <label class="block text-sm font-medium text-gray-700">Interest rate</label>
        <div class="mt-1 relative rounded-md shadow-sm">
          <input 
            :value="interestRate"
            @input="updateField('interestRate', $event.target.value)"
            type="number"
            step="0.01"
            class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
          />
          <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <span class="text-gray-500 sm:text-sm">%</span>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <label class="block text-sm font-medium text-gray-700">Term of loan (years)</label>
        <input 
          :value="numYears"
          @input="updateField('numYears', $event.target.value)"
          type="number"
          class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        />
      </div>
      <div class="mb-4">
        <label class="block text-sm font-medium text-gray-700">Closing costs</label>
        <div class="mt-1 relative rounded-md shadow-sm">
          <input 
            :value="closeCosts"
            @input="updateField('closeCosts', $event.target.value)"
            type="number"
            step="0.01"
            class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
          />
          <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <span class="text-gray-500 sm:text-sm">%</span>
          </div>
        </div>
      </div>
    </template>
    
    <template v-else>
      <div class="mb-4">
        <label class="block text-sm font-medium text-gray-700">Cost basis of the property</label>
        <div class="mt-1 relative rounded-md shadow-sm">
          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span class="text-gray-500 sm:text-sm">$</span>
          </div>
          <input 
            :value="ownValue"
            @input="updateField('ownValue', $event.target.value)"
            type="number"
            class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
          />
        </div>
        <p class="mt-2 text-xs">Total investment in the property including purchase price, closing costs, and physical improvements.</p>
      </div>
      <div class="mb-4">
        <label class="block text-sm font-medium text-gray-700">Monthly P&I payment</label>
        <div class="mt-1 relative rounded-md shadow-sm">
          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span class="text-gray-500 sm:text-sm">$</span>
          </div>
          <input 
            :value="piPayment"
            @input="updateField('piPayment', $event.target.value)"
            type="number"
            class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
          />
        </div>
        <p class="mt-2 text-xs">The principal and interest payment on your existing mortgage. Do not include insurance and taxes (escrow) as those are calculated later.</p>
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const emit = defineEmits(['section-completed']);

const showLoanLocal = computed({
  get: () => store.state.showLoan,
  set: (value) => store.commit('updateField', { field: 'showLoan', value })
});

const updateShowLoan = (value) => {
  store.commit('updateField', { field: 'showLoan', value });
  console.log('showLoan updated:', value);
};

watch(showLoanLocal, (newValue) => {
  updateShowLoan(newValue);
});

watch(() => store.state.showLoan, (newValue) => {
  showLoanLocal.value = newValue;
});


const listingUrlLocal = ref('');

const purchaseAmount = computed({
  get: () => store.state.purchaseAmount,
  set: (value) => store.commit('updateField', { field: 'purchaseAmount', value: Number(value) })
});

const downPaymentPer = computed({
  get: () => store.state.downPaymentPer,
  set: (value) => store.commit('updateField', { field: 'downPaymentPer', value: Number(value) })
});

const interestRate = computed({
  get: () => store.state.interestRate,
  set: (value) => store.commit('updateField', { field: 'interestRate', value: Number(value) })
});

const numYears = computed({
  get: () => store.state.numYears,
  set: (value) => store.commit('updateField', { field: 'numYears', value: Number(value) })
});

const closeCosts = computed({
  get: () => store.state.closeCosts,
  set: (value) => store.commit('updateField', { field: 'closeCosts', value: Number(value) })
});

const ownValue = computed({
  get: () => store.state.ownValue,
  set: (value) => store.commit('updateField', { field: 'ownValue', value: Number(value) })
});

const piPayment = computed({
  get: () => store.state.piPayment,
  set: (value) => store.commit('updateField', { field: 'piPayment', value: Number(value) })
});

const updateField = (field, value) => {
  store.commit('updateField', { field, value: Number(value) });
  if (showLoanLocal.value) {
    calculateMonthlyPayment();
  }
};

const calculateMonthlyPayment = () => {
  if (purchaseAmount.value && downPaymentPer.value && interestRate.value && numYears.value) {
    const P = purchaseAmount.value * (1 - downPaymentPer.value / 100);
    const r = interestRate.value / 1200; // monthly interest rate
    const n = numYears.value * 12; // total number of payments
    const monthlyPayment = P * (r * Math.pow(1 + r, n)) / (Math.pow(1 + r, n) - 1);
    store.commit('updateField', { field: 'piPayment', value: monthlyPayment });
  }
};

// Update the existing watcher to include listingUrlLocal
watch([showLoanLocal, purchaseAmount, downPaymentPer, interestRate, numYears, closeCosts, ownValue, piPayment, listingUrlLocal], 
  ([newShowLoan, newPurchaseAmount, newDownPaymentPer, newInterestRate, newNumYears, newCloseCosts, newOwnValue, newPiPayment]) => {
    if (newShowLoan) {
      if (newPurchaseAmount && newDownPaymentPer && newInterestRate && newNumYears && newCloseCosts) {
        emit('section-completed');
      }
    } else {
      if (newOwnValue && newPiPayment) {
        emit('section-completed');
      }
    }
  }
);

// Watch for changes in the Vuex store and update the local ref
watch(() => store.state.listingUrl, (newValue) => {
  listingUrlLocal.value = newValue;
}, { immediate: true });

// Watch for changes in the local ref and update the Vuex store
watch(listingUrlLocal, (newValue) => {
  store.commit('updateField', { field: 'listingUrl', value: newValue });
});

onMounted(() => {
  console.log('OwnershipStatus mounted, showLoan:', showLoanLocal.value);
  if (showLoanLocal.value) {
    calculateMonthlyPayment();
  }
});
</script>