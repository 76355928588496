<!-- src/components/STRCalculator/PropertyList.vue -->
<template>
  <div>
    <ul>
      <li v-for="property in properties" :key="property.id"
        class="flex items-center justify-between border-b border-slate-300 hover:bg-slate-50"
        :class="{'font-semibold text-emerald-700 bg-emerald-50': property.id === currentPropertyId}"
        >
        <button
          @click="selectProperty(property)"
          class="flex-grow text-left p-4 rounded"
        >
          {{ formatAddress(property) }}
        </button>
        <div class="flex">
          <button
            @click="shareProperty(property)"
            class="p-1 mr-2 text-slate-200 hover:bg-slate-200 hover:text-blue-500 rounded"
            title="Share property"
          >
            <svg v-if="!property.shared" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
              <path d="M9.25 13.25a.75.75 0 0 0 1.5 0V4.636l2.955 3.129a.75.75 0 0 0 1.09-1.03l-4.25-4.5a.75.75 0 0 0-1.09 0l-4.25 4.5a.75.75 0 1 0 1.09 1.03L9.25 4.636v8.614Z" />
              <path d="M3.5 12.75a.75.75 0 0 0-1.5 0v2.5A2.75 2.75 0 0 0 4.75 18h10.5A2.75 2.75 0 0 0 18 15.25v-2.5a.75.75 0 0 0-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5Z" />
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z" clip-rule="evenodd" />
            </svg>
          </button>
          <button
            @click="confirmDelete(property)"
            class="p-1 text-slate-200 hover:bg-slate-200 hover:text-red-500 rounded"
            title="Delete property"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
              <path fill-rule="evenodd" d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z" clip-rule="evenodd" />
            </svg>
          </button>
        </div>
      </li>
    </ul>
    <button
      @click="startNewProperty"
      class="mt-4 w-full py-2 px-4 bg-emerald-600 text-white rounded-full hover:bg-emerald-700 transition-colors duration-200"
    >
      New Property
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 inline">
        <path d="M10.75 4.75a.75.75 0 0 0-1.5 0v4.5h-4.5a.75.75 0 0 0 0 1.5h4.5v4.5a.75.75 0 0 0 1.5 0v-4.5h4.5a.75.75 0 0 0 0-1.5h-4.5v-4.5Z" />
      </svg>
    </button>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const store = useStore();
const router = useRouter();
const properties = ref(store.getters.getCalculatorResults.map(p => ({ ...p, shared: false })));

const currentPropertyId = computed(() => store.state.currentPropertyId);

const formatAddress = (property) => {
  const parts = [
    property.address,
    property.city,
    property.state,
    property.zip
  ].filter(Boolean);
  
  return parts.join(', ');
};

const selectProperty = async (property) => {
  try {
    await store.dispatch('fetchPropertyById', property.id);
    router.push(`/property/${property.id}`);
  } catch (error) {
    console.error('Error selecting property:', error);
    store.dispatch('showMessage', { 
      title: 'Error', 
      message: 'Failed to load the property. Please try again.' 
    });
  }
};


const startNewProperty = () => {
  store.dispatch('clearCalculatorFields');
  router.push('/');
};

const confirmDelete = (property) => {
  store.dispatch('showConfirmationModal', {
    title: 'Confirm Deletion',
    message: 'Are you sure? This cannot be undone.',
    onConfirm: () => deleteProperty(property.id)
  });
};

const deleteProperty = async (propertyId) => {
  try {
    await store.dispatch('deleteCalculatorResult', propertyId);
    properties.value = properties.value.filter(p => p.id !== propertyId);
    store.dispatch('showMessage', { title: 'Success', message: 'Property deleted successfully' });
  } catch (error) {
    console.error('Error deleting property:', error);
    store.dispatch('showMessage', { title: 'Error', message: 'Error deleting property. Please try again.' });
  }
};

const shareProperty = async (property) => {
  const url = `${window.location.origin}/property/${property.id}`;
  try {
    await navigator.clipboard.writeText(url);
    const index = properties.value.findIndex(p => p.id === property.id);
    if (index !== -1) {
      properties.value[index].shared = true;
      setTimeout(() => {
        properties.value[index].shared = false;
      }, 2000);
    }
  } catch (error) {
    console.error('Error copying to clipboard:', error);
    store.dispatch('showMessage', { title: 'Error', message: 'Failed to copy link. Please try again.' });
  }
};

</script>