<template>
  <div>
    <div class="mb-4">
      <label class="block text-sm font-medium text-gray-700">Address *</label>
      <input 
        :value="address"
        @input="updateField('address', $event.target.value)"
        type="text"
        required
        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
      />
    </div>
    <div class="mb-4">
      <label class="block text-sm font-medium text-gray-700">Address 2</label>
      <input 
        :value="address2"
        @input="updateField('address2', $event.target.value)"
        type="text"
        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
      />
    </div>
    <div class="mb-4">
      <label class="block text-sm font-medium text-gray-700">City</label>
      <input 
        :value="city"
        @input="updateField('city', $event.target.value)"
        type="text"
        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
      />
    </div>
    <div class="mb-4">
      <label class="block text-sm font-medium text-gray-700">State</label>
      <select
        :value="state"
        @change="updateField('state', $event.target.value)"
        class="mt-1 p-1 text-sm block w-full border rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
      >
        <option value="">Select a state</option>
        <option v-for="state in usStates" :key="state.abbreviation" :value="state.abbreviation">
          {{ state.name }}
        </option>
      </select>
    </div>
    <div class="mb-4">
      <label class="block text-sm font-medium text-gray-700">Zip</label>
      <input 
        :value="zip"
        @input="updateField('zip', $event.target.value)"
        type="text"
        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, watch } from 'vue';
import { useStore } from 'vuex';
import { usStates } from '@/utils/usStates';

const store = useStore();
const emit = defineEmits(['section-completed']);

const address = computed(() => store.state.address);
const address2 = computed(() => store.state.address2);
const city = computed(() => store.state.city);
const state = computed(() => store.state.state);
const zip = computed(() => store.state.zip);

const updateField = (field, value) => {
  store.commit('updateField', { field, value });
};

// Debounce function
const debounce = (fn, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn(...args), delay);
  };
};

// Debounced check completion function
const debouncedCheckCompletion = debounce(() => {
  if (address.value && city.value && state.value && zip.value) {
    emit('section-completed');
  }
}, 500);

watch([address, city, state, zip], debouncedCheckCompletion);
</script>