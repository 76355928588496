<template>
  <div class="container mx-auto py-8 px-4 sm:px-6 lg:px-8">
    <h1 class="text-3xl font-bold mb-6">User Profile</h1>
    <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <!-- Avatar section -->
      <div class="mb-6 flex justify-center">
        <div class="relative w-32 h-32 group">
          <input 
            type="file" 
            @change="handleAvatarChange" 
            accept="image/*" 
            class="hidden" 
            ref="avatarInput"
          >
          <!-- Placeholder or existing avatar -->
          <div 
            @click="$refs.avatarInput.click()"
            class="w-full h-full rounded-full bg-gray-200 flex items-center justify-center cursor-pointer overflow-hidden"
          >
            <img 
              v-if="avatarPreview || avatarUrl" 
              :src="avatarPreview || avatarUrl" 
              alt="User avatar" 
              class="w-full h-full object-cover"
            >
            <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
            </svg>
          </div>
          <!-- Pencil icon overlay -->
          <div 
            @click="$refs.avatarInput.click()"
            class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 rounded-full cursor-pointer transition-opacity duration-200"
          >
            <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
            </svg>
          </div>
        </div>
      </div>
      
      <!-- Other form fields -->
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="username">
          Username
        </label>
        <input 
          class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" 
          id="username" 
          type="username" 
          v-model="form.username"
        >
      </div>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="email">
          Email
        </label>
        <input 
          class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" 
          id="email" 
          type="email" 
          v-model="form.email"
        >
      </div>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="firstName">
          First Name
        </label>
        <input 
          class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" 
          id="firstName" 
          type="text" 
          v-model="form.firstName"
        >
      </div>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="lastName">
          Last Name
        </label>
        <input 
          class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" 
          id="lastName" 
          type="text" 
          v-model="form.lastName"
        >
      </div>
      <div class="flex items-center justify-between">
        <button 
          @click="saveChanges" 
          class="bg-emerald-500 hover:bg-emerald-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          :disabled="!isFormChanged"
        >
          Save Changes
        </button>
      </div>
      <!-- New Password Change Section -->
       <hr class="mt-8"/>
      <div class="mb-6 mt-8">
        <h2 class="text-xl font-bold mb-4">Change Password</h2>
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="currentPassword">
            Current Password
          </label>
          <input 
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" 
            id="currentPassword" 
            type="password" 
            v-model="passwordForm.currentPassword"
          >
        </div>
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="newPassword">
            New Password
          </label>
          <input 
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" 
            id="newPassword" 
            type="password" 
            v-model="passwordForm.newPassword"
          >
        </div>
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="confirmNewPassword">
            Confirm New Password
          </label>
          <input 
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" 
            id="confirmNewPassword" 
            type="password" 
            v-model="passwordForm.confirmNewPassword"
          >
        </div>
        <button 
          @click="changePassword" 
          class="bg-emerald-500 hover:bg-emerald-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          :disabled="!isPasswordFormValid"
        >
          Change Password
        </button>
      </div>
    </div>
    <MessageModal 
      :show="showMessageModal"
      :title="modalTitle"
      :message="modalMessage"
      @close="closeMessageModal"
    />
  </div>
</template>

<script setup>
import { ref, computed, reactive, watch } from 'vue';
import { useStore } from 'vuex';
import MessageModal from './MessageModal.vue';

const store = useStore();
const avatarInput = ref(null);

const initialForm = reactive({
  username: '',
  email: '',
  firstName: '',
  lastName: '',
});

const form = reactive({ ...initialForm });
const avatarFile = ref(null);
const avatarUrl = computed(() => store.getters.userAvatar);
const avatarPreview = ref(null);

const currentUser = computed(() => store.getters.currentUser);

// Watch for changes in currentUser and update the form and initialForm
watch(currentUser, (newUser) => {
  if (newUser) {
    Object.assign(initialForm, {
      username: newUser.username || '',
      email: newUser.email || '',
      firstName: newUser.firstName || '',
      lastName: newUser.lastName || '',
    });
    Object.assign(form, { ...initialForm });
  }
}, { immediate: true });

const isFormChanged = computed(() => {
  return Object.keys(form).some(key => form[key] !== initialForm[key]) || avatarFile.value !== null;
});
const handleAvatarChange = (event) => {
  const file = event.target.files[0];
  if (file) {
    avatarFile.value = file;
    const reader = new FileReader();
    reader.onload = (e) => {
      avatarPreview.value = e.target.result;
    };
    reader.readAsDataURL(file);
  }
};

const passwordForm = reactive({
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: ''
});

const isPasswordFormValid = computed(() => {
  return passwordForm.currentPassword && 
         passwordForm.newPassword && 
         passwordForm.confirmNewPassword && 
         passwordForm.newPassword === passwordForm.confirmNewPassword;
});

// New reactive variables for the modal
const showMessageModal = ref(false);
const modalTitle = ref('');
const modalMessage = ref('');

const closeMessageModal = () => {
  showMessageModal.value = false;
};

const changePassword = async () => {
  if (!isPasswordFormValid.value) {
    modalTitle.value = 'Error';
    modalMessage.value = 'Please ensure all password fields are filled and new passwords match.';
    showMessageModal.value = true;
    return;
  }

  try {
    await store.dispatch('updateUserPassword', {
      oldPassword: passwordForm.currentPassword,
      newPassword: passwordForm.newPassword
    });
    modalTitle.value = 'Success';
    modalMessage.value = 'Password updated successfully!';
    showMessageModal.value = true;
    // Clear the password form
    passwordForm.currentPassword = '';
    passwordForm.newPassword = '';
    passwordForm.confirmNewPassword = '';
  } catch (error) {
    console.error('Failed to update password:', error);
    modalTitle.value = 'Error';
    modalMessage.value = 'Failed to update password. Please try again.';
    showMessageModal.value = true;
  }
};

const saveChanges = async () => {
  try {
    // Update profile fields
    for (const [field, value] of Object.entries(form)) {
      if (value !== initialForm[field]) {
        await store.dispatch('updateUserProfile', { field, value });
      }
    }

    // Update avatar if changed
    if (avatarFile.value) {
      await store.dispatch('updateUserAvatar', avatarFile.value);
      avatarFile.value = null;
      avatarPreview.value = null;
    }

    // Fetch the updated user data
    await store.dispatch('fetchCurrentUser');

    modalTitle.value = 'Success';
    modalMessage.value = 'Profile updated successfully!';
    showMessageModal.value = true;
  } catch (error) {
    console.error('Failed to save changes:', error);
    modalTitle.value = 'Error';
    modalMessage.value = 'Failed to update profile. Please try again.';
    showMessageModal.value = true;
  }
};
</script>