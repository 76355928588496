<template>
  <div class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <div class="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
      <h2 class="text-2xl font-bold mb-4">Please {{ isLogin ? 'log in' : 'register' }} to save your results</h2>
      <form @submit.prevent="submitForm" class="space-y-4">
        <div v-if="!isLogin">
          <label for="username" class="block text-sm font-medium text-gray-700">Username</label>
          <input 
            v-model="username" 
            type="text" 
            id="username" 
            required 
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-300 focus:ring focus:ring-emerald-200 focus:ring-opacity-50"
          >
        </div>
        <div>
          <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
          <input 
            v-model="email" 
            type="email" 
            id="email" 
            required 
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-300 focus:ring focus:ring-emerald-200 focus:ring-opacity-50"
          >
        </div>
        <div>
          <label for="password" class="block text-sm font-medium text-gray-700">Password</label>
          <input 
            v-model="password" 
            type="password" 
            id="password" 
            required 
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-300 focus:ring focus:ring-emerald-200 focus:ring-opacity-50"
          >
        </div>
        <button 
          type="submit" 
          class="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
        >
          {{ isLogin ? 'Login' : 'Register' }}
        </button>
      </form>
      <button 
        @click="toggleMode" 
        class="mt-4 w-full text-sm text-emerald-600 hover:text-emerald-500"
      >
        {{ isLogin ? 'Need to register?' : 'Already have an account?' }}
      </button>
      <button 
        @click="$emit('close')" 
        class="mt-4 w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-700 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
      >
        Cancel
      </button>
    </div>
    
    <!-- Error Modal -->
    <ErrorModal 
      :show="!!error"
      :message="error"
      @close="error = ''"
    />
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'AuthModal',
  emits: ['close'],
  setup(props, { emit }) {
    const store = useStore()
    const username = ref('');
    const email = ref('');
    const password = ref('');
    const isLogin = ref(true);

    const submitForm = async () => {
      try {
        if (isLogin.value) {
          await store.dispatch('login', { email: email.value, password: password.value });
        } else {
          await store.dispatch('register', { username: username.value, email: email.value, password: password.value });
        }
        emit('close');
      } catch (error) {
        console.error('Authentication error:', error);
        // Handle error (e.g., show error message to user)
      }
    };

    const toggleMode = () => {
      isLogin.value = !isLogin.value;
      username.value = '';
      email.value = '';
      password.value = '';
    };

    return { username, email, password, isLogin, submitForm, toggleMode };
  }
}
</script>
