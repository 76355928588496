<!-- src/components/STRCalculator/STRCalculator.vue -->
<template>
  <div class="text-left container mx-auto py-8 px-4 sm:px-6 lg:px-8">
    <div class="flex flex-col sm:flex-row justify-between items-center mb-2 sm:mb-6 p-4 bg-white rounded-md border-solid border border-slate-300 drop-shadow-xl sticky top-4 z-50">
      <div>
      <h1 v-if="displayPropertyInfo" class="text-lg md:text-xl font-bold">{{ displayPropertyInfo }}</h1>
        <h1 v-else class="text-lg md:text-xl font-bold">{{ calcTitle }}</h1>
      </div>
      <div class="flex items-center mt-2 sm:mt-0">
        <button
          v-if="isEditable && (currentPropertyId || isSaved)"
          @click="copyShareLink"
          class="flex items-center justify-center h-10 min-w-[120px] text-sm font-medium mr-2 text-slate-600 hover:text-emerald-600 transition-colors duration-200"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-4 h-4 mr-1">
            <path d="M12.232 4.232a2.5 2.5 0 0 1 3.536 3.536l-1.225 1.224a.75.75 0 0 0 1.061 1.06l1.224-1.224a4 4 0 0 0-5.656-5.656l-3 3a4 4 0 0 0 .225 5.865.75.75 0 0 0 .977-1.138 2.5 2.5 0 0 1-.142-3.667l3-3Z" />
            <path d="M11.603 7.963a.75.75 0 0 0-.977 1.138 2.5 2.5 0 0 1 .142 3.667l-3 3a2.5 2.5 0 0 1-3.536-3.536l1.225-1.224a.75.75 0 0 0-1.061-1.06l-1.224 1.224a4 4 0 1 0 5.656 5.656l3-3a4 4 0 0 0-.225-5.865Z" />
          </svg>
          {{ shareButtonText }}
        </button>
        <button 
          v-if="isEditable"
          @click="handleSave" 
          :class="saveButtonClasses"
          class="flex items-center justify-center h-10 min-w-[120px] z-10 text-sm"
        >
          <span v-if="isSaved && !hasUnsavedChanges" class="flex items-center">
            <svg class="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
            </svg>
            Analysis Saved
          </span>
          <span v-else>
            {{ isEditing ? 'Save Changes' : 'Save Results' }}
          </span>
        </button>
      </div>
    </div>
    <div class="flex flex-col md:flex-row gap-8 z-0">
      <aside class="w-full md:w-1/3 px-8 py-6 bg-white rounded-md border-solid border border-slate-300 drop-shadow-xl">
        <div class="mb-8">
          <div class="flex justify-between items-center mb-4">
            <nav class="flex flex-wrap ">
              <button
                v-for="tab in tabs"
                :key="tab"
                @click="changeTab(tab)"
                class="mr-2 py-2 px-4 text-sm font-medium transition-colors duration-200 ease-in-out border-b-2"
                :class="[
                  localCurrentTab === tab
                    ? 'text-emerald-600 border-emerald-600'
                    : 'text-gray-500 border-transparent hover:text-gray-600 hover:border-gray-300'
                ]"
              >
                {{ tab }}
              </button>
            </nav>
            <button
              @click="analyzeNewProperty"
              class="p-2 text-sm font-medium bg-emerald-600 text-white rounded hover:bg-emerald-700 transition-colors duration-200"
            >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
              <path d="M10.75 4.75a.75.75 0 0 0-1.5 0v4.5h-4.5a.75.75 0 0 0 0 1.5h4.5v4.5a.75.75 0 0 0 1.5 0v-4.5h4.5a.75.75 0 0 0 0-1.5h-4.5v-4.5Z" />
            </svg>

            </button>
          </div>
        </div>
        <div v-if="localCurrentTab === 'Calculator'">
          <CollapsibleSection 
            v-for="section in sections"
            :key="section.title"
            :title="section.title" 
            :is-expanded="shouldExpandSection(section.name)"
            @toggle="toggleSection(section.name)"
          >
            <component :is="section.component" />
          </CollapsibleSection>
        </div>
        <div v-else-if="localCurrentTab === 'Properties'">
          <PropertyList v-if="isAuthenticated" @select-property="selectProperty" />
          <p v-else class="text-center text-gray-500">Please log in to view your properties.</p>
        </div>
      </aside>
      <main class="w-full md:w-2/3">
        <div class="md:sticky md:top-24">
          <ResultsDisplay 
            :is-editing="isEditing" 
            :is-editable="isEditable" 
            :calc-title="calcTitle"
            :property="displayProperty"
          />
        </div>
      </main>
    </div>
    <AuthModal 
      v-if="showAuthModal" 
      @login="handleLogin" 
      @register="handleRegister" 
      @close="closeAuthModal"
    />
    <ErrorModal 
      :show="showErrorModal"
      :message="errorMessage"
      @close="showErrorModal = false"
    />
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import CollapsibleSection from './CollapsibleSection.vue';
import PropertyInfo from './PropertyInfo.vue';
import OwnershipStatus from './OwnershipStatus.vue';
import IncomeInformation from './IncomeInformation.vue';
import ExpenseInformation from './ExpenseInformation.vue';
import TaxInformation from './TaxInformation.vue';
import ResultsDisplay from './ResultsDisplay.vue';
import PropertyList from './PropertyList.vue';
import AuthModal from '../AuthModal.vue';
import ErrorModal from '../ErrorModal.vue';

export default {
  name: 'STRCalculator',
  components: {
    CollapsibleSection,
    PropertyInfo,
    OwnershipStatus,
    IncomeInformation,
    ExpenseInformation,
    TaxInformation,
    ResultsDisplay,
    PropertyList,
    AuthModal,
    ErrorModal
  },
  props: {
    id: {
      type: String,
      default: null
    }
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const tabs = ['Calculator', 'Properties'];
    const localCurrentTab = ref(store.state.currentTab);
    const showAuthModal = ref(false);
    const pendingSave = ref(null);
    const calcTitle = ref('Short Term Rental Analysis');
    const showErrorModal = ref(false);
    const errorMessage = ref('');

    const displayPropertyInfo = computed(() => {
      if (displayProperty.value) {
        const { address, address2, city, state, zip } = displayProperty.value;
        const parts = [
          address,
          address2,
          city,
          state,
          zip
        ].filter(Boolean);
        
        return parts.join(', ');
      }
      return null;
    });

    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const currentUser = computed(() => store.getters.currentUser);
    const isNewProperty = computed(() => store.state.isNewProperty);
    const calculatorResults = computed(() => store.getters.getCalculatorResults);
    const currentPropertyId = computed(() => store.state.currentPropertyId);

    const showLoan = computed({
      get: () => store.state.showLoan,
      set: (value) => store.commit('updateField', { field: 'showLoan', value })
    });

    const displayProperty = computed(() => {
      if (currentPropertyId.value) {
        return store.getters.getCurrentProperty;
      }
      return { ...store.state, listingUrl: store.state.listingUrl };
    });

    const changeTab = (tab) => {
      if (tab === 'Properties' && !isAuthenticated.value) {
        showAuthModal.value = true;
      } else {
        localCurrentTab.value = tab;
        store.dispatch('setCurrentTab', tab);
      }
    };

    const expandedSection = ref(null);
    const isLoading = ref(true);
    const isMobile = ref(window.innerWidth < 768);

    const sections = [
      { name: 'propertyInfo', title: 'Property Info', component: PropertyInfo },
      { name: 'ownershipStatus', title: 'Ownership Status', component: OwnershipStatus },
      { name: 'incomeInformation', title: 'Income Information', component: IncomeInformation },
      { name: 'expenseInformation', title: 'Expense Information', component: ExpenseInformation },
      { name: 'taxInformation', title: 'Tax Information', component: TaxInformation },
    ];

    const initializeAccordionBehavior = () => {
      if (isMobile.value) {
        expandedSection.value = null;
      } else if (isNewProperty.value) {
        expandedSection.value = 'propertyInfo';
      }
    };

    const shouldExpandSection = (sectionName) => {
      if (isLoading.value) {
        return false;
      }
      return expandedSection.value === sectionName;
    };

    const toggleSection = (sectionName) => {
      if (expandedSection.value === sectionName) {
        expandedSection.value = null;
      } else {
        expandedSection.value = sectionName;
      }
    };

    const clearFields = () => {
      store.dispatch('clearCalculatorFields');
      initializeAccordionBehavior();
    };

    const analyzeNewProperty = () => {
      store.dispatch('clearCalculatorFields');
      store.dispatch('setCurrentProperty', null);
      router.push('/');
      changeTab('Calculator');
      initializeAccordionBehavior();
    };

    const selectProperty = (property) => {
      store.dispatch('setCurrentProperty', property.id);
      Object.keys(property).forEach(key => {
        if (key in store.state && key !== 'id' && key !== 'user') {
          if (key === 'showLoan') {
            store.commit('updateField', { field: key, value: Boolean(property[key]) });
          } else if (key === 'listingUrl') {
            store.commit('updateField', { field: key, value: property[key] || '' });
          } else {
            store.commit('updateField', { field: key, value: property[key] });
          }
        }
      });
      console.log('Property selected, showLoan:', store.state.showLoan);
      changeTab('Calculator');
      initializeAccordionBehavior();
    };

    const loadProperty = async (id) => {
      if (!id) return;
      try {
        isLoading.value = true;
        await store.dispatch('fetchPropertyById', id);
        changeTab('Calculator');
        initializeAccordionBehavior();
      } catch (error) {
        console.error('Error loading property:', error);
        store.dispatch('showMessage', { 
          title: 'Error', 
          message: 'Failed to load the property. Please try again.' 
        });
        store.dispatch('clearCalculatorFields');
        router.push('/');
      } finally {
        isLoading.value = false;
      }
    };

    const saveResults = async () => {
      console.log("Save Results called. Is Authenticated:", isAuthenticated.value);
      console.log("Current user state:", store.state.user);
      
      if (!isAuthenticated.value) {
        console.log("User not authenticated, attempting to show auth modal");
        showAuthModal.value = true;
        console.log("showAuthModal set to:", showAuthModal.value);
        pendingSave.value = store.state;
        return;
      }

      console.log("Proceeding with save operation");

      try {
        let savedProperty;
        if (currentPropertyId.value) {
          console.log("Updating existing property");
          savedProperty = await store.dispatch('updateExistingProperty', currentPropertyId.value);
        } else {
          console.log("Attempting to save new property");
          savedProperty = await store.dispatch('saveNewProperty');
        }
        
        console.log("Property saved successfully:", savedProperty);
        
        // Update the current property ID in the store
        store.commit('SET_CURRENT_PROPERTY_ID', savedProperty.id);
        
        // Update all relevant fields in the state
        Object.keys(savedProperty).forEach(key => {
          if (key in store.state && !['id', 'user', 'created', 'updated'].includes(key)) {
            store.commit('updateField', { field: key, value: savedProperty[key] });
          }
        });

        // Set isNewProperty to false
        store.commit('SET_IS_NEW_PROPERTY', false);
        
        // Navigate to the property's unique URL
        router.push(`/property/${savedProperty.id}`);
        
        // Show a success message
        store.dispatch('showMessage', { 
          title: 'Success', 
          message: currentPropertyId.value ? 'Property updated successfully' : 'New property saved successfully' 
        });
      } catch (error) {
        console.error('Error saving results:', error);
        store.dispatch('showMessage', { 
          title: 'Error', 
          message: 'Error saving results. Please try again.' 
        });
      }
    };

    const shareProperty = () => {
      if (!currentPropertyId.value) {
        // If property hasn't been saved yet, save it first
        saveResults();
      } else {
        const sharedUrl = `${window.location.origin}/property/${currentPropertyId.value}`;
        // Implement your sharing logic here (e.g., copy to clipboard, show in modal)
        console.log('Shared URL:', sharedUrl);
      }
    };

    const handleLogin = async (credentials) => {
      try {
        await store.dispatch('login', credentials);
        closeAuthModal();
        if (pendingSave.value) {
          await saveResults();
          pendingSave.value = null;
        }
      } catch (error) {
        console.error('Login failed:', error);
      }
    };

    const handleRegister = async (credentials) => {
      try {
        await store.dispatch('register', credentials);
        closeAuthModal();
        if (pendingSave.value) {
          await saveResults();
          pendingSave.value = null;
        }
      } catch (error) {
        console.error('Registration failed:', error);
      }
    };

    const closeAuthModal = () => {
      showAuthModal.value = false;
      // Remove the query parameter
      router.replace({ query: {} });
    };

    const checkPendingSave = async () => {
      const pendingSaveData = localStorage.getItem('pendingSave');
      if (pendingSaveData) {
        try {
          const data = JSON.parse(pendingSaveData);
          await store.dispatch('saveNewProperty', data);
          alert('New property saved successfully!');
        } catch (error) {
          console.error('Error saving pending property:', error);
          alert('Error saving pending property. Please try again.');
        } finally {
          localStorage.removeItem('pendingSave');
        }
      }
    };

    // Save property functions

    const isSaved = ref(false);
    const hasUnsavedChanges = ref(false);

    const shareButtonText = ref('Share Link');

    const isEditable = computed(() => true);
    const isEditing = computed(() => !!currentPropertyId.value);

    const saveButtonClasses = computed(() => {
      if (isSaved.value && !hasUnsavedChanges.value) {
        return 'py-2 px-4 text-white rounded-full bg-blue-500 hover:bg-blue-600 transition-colors duration-200';
      } else {
        return 'py-2 px-4 text-white rounded-full bg-emerald-600 hover:bg-emerald-700 transition-colors duration-200';
      }
    });

    const copyShareLink = () => {
      const propertyId = currentPropertyId.value;
      if (!propertyId) {
        errorMessage.value = 'Please save the property before sharing.';
        showErrorModal.value = true;
        return;
      }
      const url = `${window.location.origin}/property/${propertyId}`;
      navigator.clipboard.writeText(url).then(() => {
        shareButtonText.value = 'Copied!';
        setTimeout(() => {
          shareButtonText.value = 'Share Link';
        }, 2000);
      }, (err) => {
        console.error('Could not copy text: ', err);
        errorMessage.value = 'Failed to copy link. Please try again.';
        showErrorModal.value = true;
      });
    };

    const handleSave = async () => {
      console.log("HandleSave called. Is Authenticated:", isAuthenticated.value);
      if (!isAuthenticated.value) {
        console.log("User not authenticated, showing auth modal");
        showAuthModal.value = true;
        return;
      }

      // Check if address is empty
      if (!store.state.address.trim()) {
        console.log("Address is empty, showing error modal");
        errorMessage.value = 'Address is required to save the property';
        showErrorModal.value = true;
        return;
      }

      try {
        let savedProperty;
        if (currentPropertyId.value) {
          console.log("Updating existing property");
          savedProperty = await store.dispatch('updateExistingProperty', currentPropertyId.value);
        } else {
          console.log("Saving new property");
          savedProperty = await store.dispatch('saveNewProperty');
        }
        
        console.log("Property saved successfully:", savedProperty);
        isSaved.value = true;
        hasUnsavedChanges.value = false;
        store.commit('SET_CURRENT_PROPERTY_ID', savedProperty.id);
      } catch (e) {
        console.error('Error saving:', e);
        errorMessage.value = 'Error saving. Please try again.';
        showErrorModal.value = true;
      }
    };

        onMounted(async () => {
      console.log('STRCalculator mounted, id prop:', props.id);

      if (isAuthenticated.value) {
        try {
          await store.dispatch('fetchCalculatorResults');
          await checkPendingSave();
        } catch (error) {
          console.error('Error fetching calculator results:', error);
          store.dispatch('showMessage', { 
            title: 'Error', 
            message: 'Failed to load your properties. Please try again.' 
          });
        }
      }

      if (props.id) {
        await loadProperty(props.id);
      } else {
        store.dispatch('clearCalculatorFields');
      }

      initializeAccordionBehavior();
      isLoading.value = false;
      console.log('STRCalculator mounted, showLoan:', showLoan.value);

      window.addEventListener('resize', () => {
        isMobile.value = window.innerWidth < 768;
        initializeAccordionBehavior();
      });
    });

    watch(() => props.id, (newId) => {
      if (newId) {
        loadProperty(newId);
      } else {
        store.dispatch('clearCalculatorFields');
      }
    });

    watch(() => store.state.currentTab, (newTab) => {
      localCurrentTab.value = newTab;
    });

    watch(() => isAuthenticated.value, async (newValue) => {
      if (newValue) {
        try {
          await store.dispatch('fetchCalculatorResults');
          await checkPendingSave();
        } catch (error) {
          console.error('Error after authentication change:', error);
        }
      }
    });

    // Watch for route changes
    watch(() => route.fullPath, (newPath) => {
      if (newPath === '/') {
        clearFields();
      }
    });

    // Watch for changes in the route query
    watch(() => route.query.showAuthModal, (newValue) => {
      if (newValue === 'true') {
        showAuthModal.value = true;
      }
    });

    watch(() => store.state, () => {
      console.log('Store state changed, updating display property');
      if (isSaved.value) {
        hasUnsavedChanges.value = true;
        isSaved.value = false;
      }
    }, { deep: true });

    watch(() => props.id, async (newId) => {
      if (newId) {
        isLoading.value = true;
        await loadProperty(newId);
        isLoading.value = false;
        if (isMobile.value) {
          expandedSection.value = null;
        } else {
          expandedSection.value = 'propertyInfo';
        }
      } else {
        store.dispatch('clearCalculatorFields');
        expandedSection.value = 'propertyInfo';
      }
    }, { immediate: true });

    return {
      tabs,
      localCurrentTab,
      expandedSection,
      showAuthModal,
      isAuthenticated,
      currentUser,
      calculatorResults,
      currentPropertyId,
      displayProperty,
      changeTab,
      analyzeNewProperty,
      selectProperty,
      saveResults,
      shareProperty,
      handleLogin,
      handleRegister,
      closeAuthModal,
      calcTitle,
      clearFields,
      isNewProperty,
      displayPropertyInfo,
      shareButtonText,
      isSaved,
      hasUnsavedChanges,
      isEditable,
      isEditing,
      saveButtonClasses,
      showErrorModal,
      errorMessage,
      copyShareLink,
      handleSave,
      sections,
      initializeAccordionBehavior,
      shouldExpandSection,
      toggleSection,
    };
  }
};
</script>